.loader-6{
	top: 40px;
	left: -2.5px;
	width: 30px;
	display: flex;
	justify-content: space-between;
}

.loader-6 span{
	display: inline-block;
	width: 5px;
	height: 20px;
	background-color: #4583b1;
}

.loader-6 span:nth-child(1){
	animation: grow 1s ease-in-out infinite;
}

.loader-6 span:nth-child(2){
	animation: grow 1s ease-in-out 0.15s infinite;
}

.loader-6 span:nth-child(3){
	animation: grow 1s ease-in-out 0.30s infinite;
}

.loader-6 span:nth-child(4){
	animation: grow 1s ease-in-out 0.45s infinite;
}

@keyframes grow{
	0%, 100%{
		-webkit-transform: scaleY(1);
		-ms-transform: scaleY(1);
		-o-transform: scaleY(1);
		transform: scaleY(1);
	}

	50%{
		-webkit-transform: scaleY(1.8);
		-ms-transform: scaleY(1.8);
		-o-transform: scaleY(1.8);
		transform: scaleY(1.8);
	}
}