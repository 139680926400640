.not-ready-overlay {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

.not-ready-overlay.not-ready {
	filter: blur(5px);
}

.not-ready-overlay.not-ready .lds-css {
	position: absolute;
	z-index: 1;
	filter: none;
}

.not-ready-overlay.ready {
	justify-content: flex-start;
}
.not-ready-overlay .show-list-button {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	height: 40px;
	width: 20px;
	border-bottom-left-radius: 40px;
	border-top-left-radius: 40px;
	transform: translate(0, -50%);
	background-color: rgba(211, 211, 211, .7);
	box-shadow: -4px 0 6px -2px rgba(0, 0, 0, 0.2);
	cursor: pointer;
	z-index: 99;
}

.not-ready-overlay .show-list-button.hide {
	right: 0;
	transition: right .2s ease;
}

.not-ready-overlay .show-list-button.show {
	right: 464px;
	transition: right .2s ease;
}

.not-ready-overlay .show-list-button.hide .list-button-content {
	transform: rotate(0deg);
	transition: transform .2s ease;
}

.not-ready-overlay .show-list-button.show .list-button-content {
	transform: rotate(180deg);
	transition: transform .2s ease;
}

.not-ready-container .vendor-load {
	position: absolute;
	right: 232px;
	transform: translateX(50%);
	top: 100px;
	z-index: 0;
}

.not-ready-overlay .show-list-button .show-list-button-text{
	display: none;
}

@media screen and (max-width: 750px) {
	.not-ready-overlay.ready .show-list-button {
		background-color: mediumpurple;
		color: white;
		width: 160px;
		border-radius: 40px;
		top: 25px;
	}

	.not-ready-overlay.ready .show-list-button .list-button-content{
		margin-left: 5px;
		width: 20%;
	}

	.not-ready-overlay .show-list-button .show-list-button-text{
		padding: 0;
		margin-left: 30px;
		width: 80%;
		display: block;
	}

	.not-ready-overlay.ready .show-list-button.retracted {
		right: -110px;
	}

	.not-ready-overlay.ready .show-list-button.extended {
		right: -21px;
	}
}