.loading-masker {
	filter: blur(4px);
	margin-left: 2%;
	margin-right: 2%;
}

.web-cat-loader {
	display: flex;
	align-items: center;
	background-color: #4583b1;
	font-weight: bold;
	color: #ffffff;
	text-align: left;
	padding-left: 20px;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	height: 30px;
	z-index: 10;
}

.service-timeline-item {
	background: transparent;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 2px solid #b7b7b7;
	border-radius: 30px;
	padding: 10px 20px 10px 20px;
	margin: 10px auto 10px;
	color: #b7b7b7;
}

.add-test-button {
	padding: 6px 10px 6px 10px;
	margin-left: 5px;
	color: white;
	background-color: transparent;
	border: solid 2px white;
	border-radius: 3px;
	cursor: pointer;
	white-space: nowrap;
	min-width: 85px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.service-animated-background {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: transparent;
}

.service-background-row {
	display: flex;
	flex-direction: row;
}

.background-masker {
	background: transparent;
}

/* Every thing below this is just positioning */

.service-background-row.full {
	height: 8px;
}

.service-background-row.right-masker {
	height: 8px;
	justify-content: flex-end;
}

.background-masker.service-header-full {
	height: 100%;
	width: 100%;
}

.background-masker.service-header-right {
	width: 50%;

}

.background-masker.service-subheader-right {
	width: 75%;
}

.button {
	margin-right: 60px;
}
