.add-test-block {
	display: flex;
	align-items: center;
	font-weight: bold;

}

.add-test-price {
	color: #b7b7b7;
	padding-right: 10px;
	font-weight: normal;
	white-space: nowrap;
}

.add-test-block .add-test-button {
	position: relative;
	overflow: hidden;
	padding: 6px 10px 6px 10px;
	margin-left: 5px;
	color: #ddd;
	background-color: transparent;
	border: solid 2px white;
	border-radius: 3px;
	cursor: pointer;
	white-space: nowrap;
	min-width: 85px;
	max-height: 36px;
	font-size: 18px;
}

.add-test-block .add-test-button.thinking {
	overflow: visible;
	transition: none;
}

@media only screen and (min-width: 600px) {
	.add-test-block .add-test-button:hover {
		color: #4583b1;
	}

	.add-test-block .add-test-button:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: white;
		transition: transform .2s ease;
		transform: translateX(100%);
		z-index: -1;
	}

	.add-test-block .add-test-button.thinking:after {
		transform: translateX(0) !important;
	}

	.add-test-block .add-test-button:hover:after {
		transition: transform .2s ease;
		transform: translateX(0);
	}

}

@media only screen and (max-width: 600px) {

	.add-test-block {
		height: 65px;
	}

	.add-test-block .add-test-button {
		display: flex;
		align-items: center;
		align-self: normal;
		border-top: none;
		border-bottom: none;
		border-right: none;
		border-radius: 0;
		height: 65px;
		max-height: 65px;
		box-shadow: none;
	}

	.add-test-block .add-test-button:after {
		width: 110%;
	}
	
	.add-test-block .add-test-button.thinking {
		background-color: white;
		color: #4583b1;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		width: 70%;
		padding-left: 0;
	}
}