.kingdom-button {
	display: flex;
	cursor: pointer;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-grow: 0;
	overflow: hidden;
	height: 65px;
	font-size: 1em;
	flex-shrink: 0;
	margin: 0 10px 0 10px;
	padding: 0 10px 0 10px;
	box-shadow: none;
	background-color: rgba(59, 132, 177, 1);
	border-top: 1px solid gray;
	border-radius: 12px;
	transform: scale(1);
}

.kingdom-button:hover {
	background-color: rgba(119, 171, 216, 1);
	transform: scale(1.1);
}

.kingdom-button.active {
	background-color: rgba(119, 171, 216, 1);
	transform: scale(1.1);
}

.kingdom-button {
	-webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.service-selector.fade-enter .kingdom-button{
	opacity: 0;
}

.service-selector.fade-enter-done .kingdom-button{
	opacity: 1;
	transition: opacity .2s ease, transform .1s ease;
}

.service-selector.fade-exit .kingdom-button{
	opacity: 1;
}

.service-selector.fade-exit-active .kingdom-button{
	opacity: 0;
	transition: opacity .2s ease;
}

.kingdom-button, .kingdom-button path {
	box-shadow: none;
	background-color: #4583b1;
	color: #ffffff;
	fill: #ffffff;
}

.kingdom-button svg {
	height: 25px;
	width: 30px;
	flex-basis: 30px;
	flex-shrink: 1;
}

.kingdom-label-mobile {
	display:none;
}


@media only screen and (max-width: 600px) {
	.kingdom-button {
		border-radius: 50px;
		overflow: visible;
	}
	.kingdom-buttons .kingdom-label {
		display: none;
	}

	.kingdom-buttons .kingdom-label-mobile {
		display: block;
		position: absolute;
		text-align: center;
		top: -2.6em;
	}

	.kingdom-buttons .kingdom-button {
		height: 50px;
	}
}


