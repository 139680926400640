.phylum-panel-container {
	position: absolute;
	top: 50px;
	left: 0;
	height: 505px;
	width: 100%;
	overflow-y: hidden;
	overflow-x: auto;
}
.phylum-panel {
	width: 52%;
	height: 355px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: absolute;
	left: 50%;
	top: 150px;
	z-index: 101;
	transform: translate(-50%,0);
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: #b7b7b7 transparent;
}

.phylum-panel::-webkit-scrollbar {
	background-color: transparent;
	width: 5px;
}
.phylum-panel::-webkit-scrollbar-thumb {
	background-color: #b7b7b7;
	border-radius: 20px;
}


.phylum-panel-title {
	position: absolute;
	white-space: nowrap;
	left: 50%;
	top: 110px;
	transform:translateX(-50%);
	color: #dadada;
	font-size: 2em;
}

.phylum-panel-back-button {
	position: absolute;
	display: flex;
	align-items: center;
	width: 45px;
	height: 45px;
	overflow: hidden;
	white-space: nowrap;
	left: 25%;
	top: 100px;
	font-size: 1em;
	color: #dadada;
	border: 2px solid #dadada;
	cursor: pointer;
	border-radius: 30px;
	padding-left:10px;
	padding-right: 10px;
	justify-content: space-between;
	transition: transform .2s ease, width .2s ease;
}

.phylum-panel-back-button .svg-inline--fa {
	font-size: 2em !important;
}

.back-button-left-arrow {
	margin-left: 2px;
	margin-right: 14px;
}

.phylum-panel-back-button:hover {
	width: 125px;
	transition: width .2s ease;
	color: white;
	background-color: #4583b1;
}

.phylum-panel-container.fade-enter .phylum-panel,
.phylum-panel-container.fade-enter .phylum-panel-title,
.phylum-panel-container.fade-enter .phylum-panel-back-button {
	opacity: 0;
}

.phylum-panel-container.fade-enter-done .phylum-panel,
.phylum-panel-container.fade-enter-done .phylum-panel-title,
.phylum-panel-container.fade-enter-done .phylum-panel-back-button {
	opacity: 1;
	transition: opacity .2s ease, width .2s ease;
}

.phylum-panel-container.fade-exit .phylum-panel,
.phylum-panel-container.fade-exit .phylum-panel-title,
.phylum-panel-container.fade-exit .phylum-panel-back-button {
	opacity: 1;
}

.phylum-panel-container.fade-exit-active .phylum-panel,
.phylum-panel-container.fade-exit-active .phylum-panel-title,
.phylum-panel-container.fade-exit-active .phylum-panel-back-button {
	opacity: 0;
	transition: opacity .2s ease;
}

.phylum-panel-container.slide-exit .phylum-panel{
	transform: translate(-50%,0);
}

.phylum-panel-container.slide-exit-active .phylum-panel {
	transform: translate(-200%,0);
	transition: transform .2s ease;
}

.phylum-panel-container.slide-enter .phylum-panel {
	transform: translate(-200%,0);
}

.phylum-panel-container.slide-enter-active .phylum-panel {
	transform: translate(-50%,0);
	transition: transform .2s ease;
}

.phylum-panel-container.slide-exit .phylum-panel-back-button {
	transform: translate(0,0);
	opacity: 1;
}

.phylum-panel-container.slide-exit-active .phylum-panel-back-button {
	transform: translate(-100%,0);
	opacity: 0;
	transition: transform .2s ease, opacity .2s ease;
}

.phylum-panel-container.slide-enter .phylum-panel-back-button{
	transform: translate(-100%,0);
	opacity: 0;
}

.phylum-panel-container.slide-enter-active .phylum-panel-back-button{
	transform: translate(0,0);
	opacity: 1;
	transition: transform .2s ease, opacity .2s ease;
}

.phylum-panel-container.slide-exit .phylum-panel-title {
	transform: translate(-50%,0);
	opacity: 1;
}

.phylum-panel-container.slide-exit-active .phylum-panel-title {
	transform: translate(-150%,0);
	opacity: 0;
	transition: transform .2s ease, opacity .2s ease;
}

.phylum-panel-container.slide-enter .phylum-panel-title{
	transform: translate(-150%,0);
	opacity: 0;
}

.phylum-panel-container.slide-enter-active .phylum-panel-title{
	transform: translate(-50%,0);
	opacity: 1;
	transition: transform .2s ease, opacity .2s ease;
}


@media only screen and (max-width: 1200px) {
	.phylum-panel {
		width: 75%;
	}
	.phylum-panel-back-button {
		left: 14%;
	}
	.phylum-panel .list-item {
		margin-left: 2%;
		margin-right: 2%;
	}
}

@media only screen and (max-width: 850px) {
	.phylum-panel {
		width: 100%;
	}
	.phylum-panel-back-button {
		left: 2%;
		color: white;
		background-color: #4583b1;
	}
}

@media only screen and (max-width: 600px) {
	.phylum-panel-title {
		top: 20px;
		text-align: center;
		font-size: 1.5em;
		color: #dadada;
	}
	.phylum-panel-back-button {
		top: 8px;
		height: 45px;
	}
	.phylum-panel-back-button:hover {
		width: 45px;
	}
	.phylum-panel {
		top:60px;
		height: 495px;
	}
}

