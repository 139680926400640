.service-selector {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	left:0;
	top:0;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0,.95);
	z-index: 100;
}

.service-selector-inner {
	height: 100%;
	width: 100%;
}

.service-selector.fade-enter {
	background-color: rgba(0,0,0,0);
}

.service-selector.fade-enter-active {
	background-color: rgba(0,0,0,.95);
	transition: background-color .2s ease .2s;
}

.service-selector.fade-exit {
	background-color: rgba(0,0,0,0.8);
}

.service-selector.fade-exit-active {
	background-color: rgba(0,0,0,0);
	transition: background-color .2s ease .2s;
}


.address-container{
	position: absolute;
	left: 50%;
	margin-left: -150px;
	margin-top: -20px;
	top:25px;
	text-align: center;
	overflow: visible;
	width: 300px;
	border: 1px solid white;
	cursor: pointer;
	z-index: 101;
	padding: 5px 0;
	overflow: hidden;
	border-radius: 3px;
}

.service-selector.fade-enter .address-container{
	opacity: 0;
	transition: opacity 0.2s ease 0.2s;
}
.service-selector.fade-enter-active .address-container{
	opacity: 1;
}
.service-selector.fade-exit .address-container{
	opacity: 1;
	transition: opacity 0.2s ease 0.2s;

}
.service-selector.fade-exit-active .address-container{
	opacity: 0;
}


.address-text {
	color: white;
	line-height: 1 !important;
	font-size: 18px !important;
	padding: 0;
	margin: 0;
}

.address-text.desc{
	font-size: 12px !important;
	margin-bottom: 3px;
}



.address-container:hover {
	color: #efefef;
}

.address-container:after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #3fa1c2;
	transition: transform .2s ease;
	transform: translateX(100%);
	z-index: -1;
}

.address-container:hover:after {
	transition: transform .2s ease;
	transform: translateX(0);
}