@keyframes lds-double-ring {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes lds-double-ring {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes lds-double-ring_reverse {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(-360deg);
		transform: rotate(-360deg);
	}
}

@-webkit-keyframes lds-double-ring_reverse {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(-360deg);
		transform: rotate(-360deg);
	}
}

.lds-double-ring {
	position: relative;
}

.lds-double-ring div {
	position: absolute;
	width: 160px;
	height: 160px;
	top: 20px;
	left: 20px;
	border-radius: 50%;
	border: 20px solid #000;
	border-color: #4583b1 transparent #4583b1 transparent;
	-webkit-animation: lds-double-ring 1.3s linear infinite;
	animation: lds-double-ring 1.3s linear infinite;
}

.lds-double-ring div:nth-child(2) {
	width: 116px;
	height: 116px;
	top: 42px;
	left: 42px;
	border-color: transparent #dedede transparent #dedede;
	-webkit-animation: lds-double-ring_reverse 1.3s linear infinite;
	animation: lds-double-ring_reverse 1.3s linear infinite;
}

.lds-double-ring {
	width: 200px !important;
	height: 200px !important;
	-webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
	transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}

.lds-double-ring-mini div {
	position: absolute;
	width: 160px;
	height: 160px;
	top: 20px;
	left: 20px;
	border-radius: 50%;
	border: 20px solid #000;
	border-color: #4583b1 transparent #4583b1 transparent;
	-webkit-animation: lds-double-ring 1.3s linear infinite;
	animation: lds-double-ring 1.3s linear infinite;
}

.lds-double-ring-mini div:nth-child(2) {
	width: 116px;
	height: 116px;
	top: 42px;
	left: 42px;
	border-color: transparent #dedede transparent #dedede;
	-webkit-animation: lds-double-ring_reverse 1.3s linear infinite;
	animation: lds-double-ring_reverse 1.3s linear infinite;
}

.lds-double-ring-mini {
	width: 200px !important;
	height: 200px !important;
	-webkit-transform: translate(-100px, -100px) scale(0.4) translate(250px, 35px);
	transform: translate(-100px, -100px) scale(0.4) translate(250px, 35px);
}