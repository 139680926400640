.subtype-loading-masker {
	padding: 10px 20px 10px 20px;
	border-bottom: 2px solid #d6d7d6;
}
.subtype-timeline-item {
	background: #fff;
	border-bottom: 1px solid #f2f2f2;
	padding: 10px 20px 10px 20px;
	margin: 0 auto;
	overflow: hidden;
}

@-webkit-keyframes subtypePlaceHolderShimmer {
	0% {
		background-position: -468px 0
	}
	100% {
		background-position: 468px 0
	}
}

@keyframes subtypePlaceHolderShimmer {
	0% {
		background-position: -468px 0
	}
	100% {
		background-position: 468px 0
	}
}

.subtype-animated-background {
	-webkit-animation-duration: 1s;
	        animation-duration: 1s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
	-webkit-animation-name: subtypePlaceHolderShimmer;
	        animation-name: subtypePlaceHolderShimmer;
	-webkit-animation-timing-function: linear;
	        animation-timing-function: linear;
	background: #f6f7f8;
	background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
	background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
	background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
	background-size: 800px 104px;
	height: 20px;
	position: relative;
}

.subtype-background-masker {
	background: #fff;
	position: absolute;
}

/* Every thing below this is just positioning */

.subtype-background-masker.header-left{
	top: 0;
	left: 200px;
	height: 20px;
	width: 350px;
}



.loading-masker {
	-webkit-filter: blur(4px);
	        filter: blur(4px);
	margin-left: 2%;
	margin-right: 2%;
}

.web-cat-loader {
	display: flex;
	align-items: center;
	background-color: #4583b1;
	font-weight: bold;
	color: #ffffff;
	text-align: left;
	padding-left: 20px;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	height: 30px;
	z-index: 10;
}

.service-timeline-item {
	background: transparent;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 2px solid #b7b7b7;
	border-radius: 30px;
	padding: 10px 20px 10px 20px;
	margin: 10px auto 10px;
	color: #b7b7b7;
}

.add-test-button {
	padding: 6px 10px 6px 10px;
	margin-left: 5px;
	color: white;
	background-color: transparent;
	border: solid 2px white;
	border-radius: 3px;
	cursor: pointer;
	white-space: nowrap;
	min-width: 85px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.service-animated-background {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: transparent;
}

.service-background-row {
	display: flex;
	flex-direction: row;
}

.background-masker {
	background: transparent;
}

/* Every thing below this is just positioning */

.service-background-row.full {
	height: 8px;
}

.service-background-row.right-masker {
	height: 8px;
	justify-content: flex-end;
}

.background-masker.service-header-full {
	height: 100%;
	width: 100%;
}

.background-masker.service-header-right {
	width: 50%;

}

.background-masker.service-subheader-right {
	width: 75%;
}

.button {
	margin-right: 60px;
}

.map {
	width: 100%;
	-webkit-transition: width .2s ease;
	transition: width .2s ease;
}

.map.show-list {
	width: 100%;
	-webkit-transition: width .2s ease;
	transition: width .2s ease;
}
.not-ready-overlay {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

.not-ready-overlay.not-ready {
	-webkit-filter: blur(5px);
	        filter: blur(5px);
}

.not-ready-overlay.not-ready .lds-css {
	position: absolute;
	z-index: 1;
	-webkit-filter: none;
	        filter: none;
}

.not-ready-overlay.ready {
	justify-content: flex-start;
}
.not-ready-overlay .show-list-button {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	height: 40px;
	width: 20px;
	border-bottom-left-radius: 40px;
	border-top-left-radius: 40px;
	-webkit-transform: translate(0, -50%);
	        transform: translate(0, -50%);
	background-color: rgba(211, 211, 211, .7);
	box-shadow: -4px 0 6px -2px rgba(0, 0, 0, 0.2);
	cursor: pointer;
	z-index: 99;
}

.not-ready-overlay .show-list-button.hide {
	right: 0;
	-webkit-transition: right .2s ease;
	transition: right .2s ease;
}

.not-ready-overlay .show-list-button.show {
	right: 464px;
	-webkit-transition: right .2s ease;
	transition: right .2s ease;
}

.not-ready-overlay .show-list-button.hide .list-button-content {
	-webkit-transform: rotate(0deg);
	        transform: rotate(0deg);
	-webkit-transition: -webkit-transform .2s ease;
	transition: -webkit-transform .2s ease;
	transition: transform .2s ease;
	transition: transform .2s ease, -webkit-transform .2s ease;
}

.not-ready-overlay .show-list-button.show .list-button-content {
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg);
	-webkit-transition: -webkit-transform .2s ease;
	transition: -webkit-transform .2s ease;
	transition: transform .2s ease;
	transition: transform .2s ease, -webkit-transform .2s ease;
}

.not-ready-container .vendor-load {
	position: absolute;
	right: 232px;
	-webkit-transform: translateX(50%);
	        transform: translateX(50%);
	top: 100px;
	z-index: 0;
}

.not-ready-overlay .show-list-button .show-list-button-text{
	display: none;
}

@media screen and (max-width: 750px) {
	.not-ready-overlay.ready .show-list-button {
		background-color: mediumpurple;
		color: white;
		width: 160px;
		border-radius: 40px;
		top: 25px;
	}

	.not-ready-overlay.ready .show-list-button .list-button-content{
		margin-left: 5px;
		width: 20%;
	}

	.not-ready-overlay .show-list-button .show-list-button-text{
		padding: 0;
		margin-left: 30px;
		width: 80%;
		display: block;
	}

	.not-ready-overlay.ready .show-list-button.retracted {
		right: -110px;
	}

	.not-ready-overlay.ready .show-list-button.extended {
		right: -21px;
	}
}
@keyframes lds-double-ring {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes lds-double-ring {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes lds-double-ring_reverse {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(-360deg);
		transform: rotate(-360deg);
	}
}

@-webkit-keyframes lds-double-ring_reverse {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(-360deg);
		transform: rotate(-360deg);
	}
}

.lds-double-ring {
	position: relative;
}

.lds-double-ring div {
	position: absolute;
	width: 160px;
	height: 160px;
	top: 20px;
	left: 20px;
	border-radius: 50%;
	border: 20px solid #000;
	border-color: #4583b1 transparent #4583b1 transparent;
	-webkit-animation: lds-double-ring 1.3s linear infinite;
	animation: lds-double-ring 1.3s linear infinite;
}

.lds-double-ring div:nth-child(2) {
	width: 116px;
	height: 116px;
	top: 42px;
	left: 42px;
	border-color: transparent #dedede transparent #dedede;
	-webkit-animation: lds-double-ring_reverse 1.3s linear infinite;
	animation: lds-double-ring_reverse 1.3s linear infinite;
}

.lds-double-ring {
	width: 200px !important;
	height: 200px !important;
	-webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
	transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}

.lds-double-ring-mini div {
	position: absolute;
	width: 160px;
	height: 160px;
	top: 20px;
	left: 20px;
	border-radius: 50%;
	border: 20px solid #000;
	border-color: #4583b1 transparent #4583b1 transparent;
	-webkit-animation: lds-double-ring 1.3s linear infinite;
	animation: lds-double-ring 1.3s linear infinite;
}

.lds-double-ring-mini div:nth-child(2) {
	width: 116px;
	height: 116px;
	top: 42px;
	left: 42px;
	border-color: transparent #dedede transparent #dedede;
	-webkit-animation: lds-double-ring_reverse 1.3s linear infinite;
	animation: lds-double-ring_reverse 1.3s linear infinite;
}

.lds-double-ring-mini {
	width: 200px !important;
	height: 200px !important;
	-webkit-transform: translate(-100px, -100px) scale(0.4) translate(250px, 35px);
	transform: translate(-100px, -100px) scale(0.4) translate(250px, 35px);
}
.location-selector {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 555px;
}

.location-hero {
    width: 100%;
    z-index: 100;
    height: 555px;
    background-color: #3fa1c2;
    overflow: hidden;
    position: absolute;
}

.location-hero-wrapper {
    /* z-index: 97; */
    width: 100%;
    height: 555px;
    max-width: 1366px;
    min-width: 320px;
    position: relative;
    margin: auto;
}

.slogan {
    position: absolute;
    left: 15%;
    top: 30%;
    width: 500px;
    text-align: left;
    z-index: 99;
}

.slogan h2 {
    line-height: 1.2381em;
    background: transparent;
    color: white;
    font-size: 2.5em;
    word-wrap: break-word;
    padding: 0;
    margin: 0;
}

.location-form-wrapper {
    width: 100%;
    height: 555px;
    max-width: 1366px;
    position: absolute;
    overflow: hidden;
    z-index: 101;
}

.location-form-wrapper.small {
    left: 0;
    top: 0;
    width: 600px;
    height: 70px;
}

.loc-input-form {
    position: absolute;
    left: 15%;
    top: 50%;
    display: flex;
    justify-content: space-between;
    width: 565px;
    z-index: 99;

    -webkit-transition: all 0.5s;

    transition: all 0.5s;
}

.loc-input-form.small {
    top: 10px;
    left: 10px;
    -webkit-transform-origin: top left;
            transform-origin: top left;
}

/* We need to hide the serach bar on desktop view when in service selector pipeline*/
.loc-input-form.small.menus {
    display: none;
}
.location-form-wrapper.menus {
    display: none;
}

.location-loading-icon {
    width: 50px;
    position: absolute;
    left: 62%;
    top: 5%;
    z-index: 100;

    -webkit-transition: all 0.5s;

    transition: all 0.5s;
}

.location-loading-icon.small {
    top: 0;
    left: 0;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    zoom: 80%;
}

.location-input-container {
    height: 60px;
    width: 410px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #ccc;
    position: relative;
}

.location-input-container p {
    padding-top: 0;
    position: absolute;
    top: 2px;
    left: 12px;
    font-size: 12px;
    font-weight: 400;
    color: #000;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: text;
    z-index: 99;
    margin-top: 0;
}

#location-search-input {
    margin-bottom: 5px;
    box-sizing: border-box;
    height: 50px;
    width: 100%;
    font-size: 24px;
    font-weight: 300;
    border-radius: 4px;
    z-index: 98;
    padding: 28px 10px 10px;
    outline: none;
    border: none;
    max-width: 400px;
    position: absolute;
    bottom: 0;
    left: 0;
}

#location-search-input:focus {
    outline: none;
    box-shadow: none;
}

.hs-button {
    height: 60px;
    width: 150px;

    cursor: pointer;
    border-radius: 4px;
    outline: none;

    -webkit-transition: color 0.1s, background-color 0.1s;

    transition: color 0.1s, background-color 0.1s;

    color: #333;
    background-color: #ffe82d;
    border: 1px solid #decb2f;
    position: relative;
    cursor: pointer;
}

.hs-button::before {
    content: 'Search';
    font-size: 20px;
    font-weight: 600;
}

.hs-button .text {
    position: absolute;
    left: 0;
    top: 0;
    line-height: 57px;
    width: 100%;
    font-weight: 600;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.hs-button .icon {
    left: 45%;
    top: 1px;
    position: absolute;
    opacity: 0;
    line-height: 56px;
    -webkit-transition: opacity 0.1s, -webkit-transform 0.1s;
    transition: opacity 0.1s, -webkit-transform 0.1s;
    transition: transform 0.1s, opacity 0.1s;
    transition: transform 0.1s, opacity 0.1s, -webkit-transform 0.1s;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.hs-button:hover {
    color: #eee;
    background-color: #e0d581;
    border: 1px solid #bdb36b;
}

.hs-button:hover .icon {
    opacity: 1;
    -webkit-transform: translateX(45px);
            transform: translateX(45px);
}

.hs-button:active {
    background-color: #e7b642;
    -webkit-transform: translate(1px, 1px);
            transform: translate(1px, 1px);
}

.location-input-error.fade-enter {
    opacity: 0;
}
.location-input-error.fade-enter-active {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
}
.location-input-error {
    width: 410px;
    height: 30px;
    background-color: #fffce0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 70px;
    border-radius: 5px;
    box-shadow: 1px 1px 3px 0px #00000033;
}

.location-input-error.fade-exit-active {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
}

.location-input-error svg {
    color: #ff684c;
    margin-left: 7px;
}

.location-input-error-text {
    padding: 0;
    color: #ff684c;
    margin-left: 7px;
}

/* MID SIZE MAP LOCATION ALTERATIONS */
@media screen and (max-width: 1050px) {
    .loc-input-form.small {
        left: 0;
        top: 0;
        width: 100%;
    }

    .location-form-wrapper.small {
        left: 0;
        top: 0;
        width: 100%;
    }

    .location-input-container {
        width: 80%;
        left: 0;
        border-radius: 0;
    }

    #location-search-input {
        border-radius: 0;
    }
    .hs-button {
        position: absolute;
        width: 20%;
        right: 0;
        top: 0;
        border-radius: 0;
    }

    .hs-button:before {
        content: '';
    }
    .hs-button .hs-button-search {
        font-size: 20px;
    }

    .logo {
        margin-top: 9px;
    }

    .hs-button:hover {
        color: #333;
        background-color: #ffe82d;
        border: 1px solid #decb2f;
    }
    .hs-button:hover .icon {
        opacity: 0;
    }

    .location-input-error {
        top: 110px;
        left: 8px;
    }
}

/* MOBILE SIZE MAP LOCATION ALTERATIONS */
@media screen and (max-width: 750px) {
    .loc-input-form {
        left: 5%;
        width: 90%;
        display: block;
    }
    .slogan {
        width: 100%;
        text-align: center;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
    }
    .loc-input-form.small {
        left: 0;
        top: 0px;
    }
    #slide-out {
        padding-top: 0px;
    }

    .service-selector {
        margin-top: 48px;
    }

    .location-form-wrapper.small {
        height: 60px;
    }
}

/* HACKS FOR TINY SCREENS (e.g. iPhone 5) */
@media screen and (max-width: 400px) {
    .slogan {
        zoom: 90%;
        top: 35%;
    }
}

/*
	HERO AND ICON STYLES
*/
.ground img,
.city img,
.truck img,
.cloud img,
.plane img,
.mountains img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

.ground {
    z-index: 101;
    position: absolute;
    bottom: -150px;
}

.ground img {
    width: 2000px;
}

.city {
    position: absolute;
    bottom: -10px;
    right: 0;
    width: 400px;
    z-index: 2;
}

.city img {
    width: 100%;
}

.truck {
    position: absolute;
    bottom: 12px;
    left: 15%;
    width: 80px;
    z-index: 1;
}

.truck img {
    width: 100%;
}

.plane {
    right: 15%;
    top: 100px;
}

.mountains {
    position: absolute;
    min-width: 440px;
}

.mountains img {
    width: 100%;
    overflow: hidden;
}

.mountains.m1 {
    width: 40%;
    top: 80%;
    left: 5%;
}

.mountains.m2 {
    width: 40%;
    top: 70%;
    right: 5%;
}

.icon {
    position: absolute;
}
.icon img {
    width: 100px;
}

.cloud {
}
.cloud.c1 {
    left: 3%;
}
.cloud.c2 {
    right: 5%;
    top: 0;
}
.cloud.c3 {
    right: 20%;
}
.cloud.c4 {
    left: 350px;
    top: 20px;
}

.smallicon img {
    width: 70px;
}
.mediumicon img {
    width: 150px;
}
.largeicon img {
    width: 200px;
}

/* MOBILE HERO CUSTOMIZATIONS */
@media screen and (max-width: 750px) {
    .location-selector {
        /* top: 6px; */
    }

    .city {
        width: 200px;
        bottom: 20px;
    }

    .truck {
        width: 50px;
        bottom: 15px;
    }

    .cloud.c1 {
        left: 3%;
        top: 70px;
    }
    .cloud.c2 {
        display: none;
    }
    .cloud.c3 {
        right: 7%;
        top: 70px;
    }
    .cloud.c4 {
        display: none;
    }

    .mountains.m2 {
        display: none;
    }
}

.gm-bundled-control-on-bottom.inset {
    right: 520px !important;
    -webkit-transition: right 0.3s;
    transition: right 0.3s;
}

.pulse {
    -webkit-animation: pulse 1.3s infinite;
            animation: pulse 1.3s infinite;
    box-shadow: 0px 0px 0 8px #ffe82d;
}

@-webkit-keyframes pulse {
    0% {
        box-shadow: 0px 0px 0 0px #ffe82d;
    }
    50% {
        box-shadow: 0px 0px 0 8px #ffe82d55;
    }
    100% {
        box-shadow: 0px 0px 0 0px #ffe82d;
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0px 0px 0 0px #ffe82d;
    }
    50% {
        box-shadow: 0px 0px 0 8px #ffe82d55;
    }
    100% {
        box-shadow: 0px 0px 0 0px #ffe82d;
    }
}

.modal-dialog {
	height: 0;
	width: 0;
}

.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0,0.7);
	z-index: 999;
}

.modal-dialog.modal-enter .modal-backdrop {
	background-color: rgba(0,0,0,0);
	-webkit-transition: background-color .2s ease;
	transition: background-color .2s ease;
}

.modal-dialog.modal-exit-active .modal-backdrop {
	background-color: rgba(0,0,0,0) !important;
	-webkit-transition: background-color .2s ease .5s !important;
	transition: background-color .2s ease .5s !important;
}

.modal-dialog.modal-enter-active .modal-backdrop,
.modal-dialog.modal-exit .modal-backdrop {
	background-color: rgba(0,0,0,0.7);
	-webkit-transition: background-color .2s ease;
	transition: background-color .2s ease;
}

.modal-dialog.modal-enter .modal-content-container,
.modal-dialog.modal-exit-active .modal-content-container {
	width: 0;
	-webkit-transition: width .2s ease .3s;
	transition: width .2s ease .3s
}

.modal-dialog.modal-enter-active .modal-content-container {
	width: 100%;
	-webkit-transition: width .2s ease .2s;
	transition: width .2s ease .2s;
}

.modal-dialog.modal-enter .modal-header,
.modal-dialog.modal-enter .modal-body,
.modal-dialog.modal-enter .modal-close,
.modal-dialog.modal-exit-active .modal-header,
.modal-dialog.modal-exit-active .modal-body,
.modal-dialog.modal-exit-active .modal-close {
	opacity: 0 !important;
	-webkit-transition: opacity .2s ease;
	transition: opacity .2s ease;
	overflow: hidden;
}


.modal-dialog.modal-enter-done .modal-header,
.modal-dialog.modal-enter-done .modal-body,
.modal-dialog.modal-enter-done .modal-close,
.modal-dialog.modal-exit .modal-header,
.modal-dialog.modal-exit .modal-body,
.modal-dialog.modal-exit .modal-close {
	opacity: 1;
	overflow: auto;
	-webkit-transition: opacity .2s ease .1s;
	transition: opacity .2s ease .1s;
}


.modal-content-container {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	top: 50%;
	left:50%;
	-webkit-transform: translate(-50%,-50%);
	        transform: translate(-50%,-50%);
	width: 100%;
	height: 555px;
	background-color: white;
	z-index: 1000;
	max-width: 700px;
}

.modal-close {
	position: absolute;
	right: 20px;
	color: #aaaaaa;
	font-weight: bold;
	cursor: pointer;
}

.modal-close:hover,
.modal-close:focus {
	color: #000000;
	cursor: pointer;
}

.modal-header {
	display: flex;
	justify-content: center;
	font-weight: bold;
	font-size: 1.5em;
	margin-top: 10px;
	margin-bottom: 10px;
	color: #4583b1;
}
.modal-body {
	overflow: auto;
	height: 463px;
}
.modifier-drawer-container {
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0,0.7);
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modifier-drawer-container.modal-exit-active {
	background-color: rgba(0,0,0,0);
	-webkit-transition: background-color .2s ease-out .5s;
	transition: background-color .2s ease-out .5s;
}

.modifier-drawer {
	background-color: #e4eef3;
	opacity: .95;
	padding: 10px 10px 10px 10px;
	border-radius: 5px;
}

.modifier-header {
	display: flex;
	justify-content: space-between;
	border-bottom: 2px solid #d3d3d3;
}

.centered{
	justify-content: center;
	text-align: center;
}

.modifier-closer {
	cursor: pointer;
}

.modifier-title {
	font-size: 1.2em;
	font-weight: bold;
}

.modifier-footer {
	display: flex;
	flex-direction: column;
	align-items: center;
}


.modifier-confirm {
	position: relative;
	overflow: hidden;
	padding: 6px 10px 6px 10px;
	margin-left: 5px;

	background-color: transparent;
	border: solid 2px #4583b1;
	border-radius: 3px;
	cursor: pointer;
	white-space: nowrap;
	min-width: 85px;
	max-height: 36px;
}

.modifier-cancel {
	color: #FF3C38;
	background-color: transparent;
	font-size: .8em;
	text-decoration: underline;
	cursor: pointer;
}

.modifier-cancel:hover {
	text-shadow: 0px 0px 20px #FF3C38;
}

.modifier-confirm {
	color: #4583b1;
}

.modifier-confirm:after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	-webkit-transition: -webkit-transform .2s ease;

	transition: -webkit-transform .2s ease;

	transition: transform .2s ease;

	transition: transform .2s ease, -webkit-transform .2s ease;

	z-index: -1;
}

.modifier-confirm:after {
	background-color: #4583b1;
	-webkit-transform: translateX(100%);
	        transform: translateX(100%);
}

.modifier-confirm:hover {
	color: white;
}

.modifier-confirm:hover:after {
	-webkit-transition: -webkit-transform .2s ease;
	transition: -webkit-transform .2s ease;
	transition: transform .2s ease;
	transition: transform .2s ease, -webkit-transform .2s ease;
	-webkit-transform: translateX(0);
	        transform: translateX(0);
}

.feature-name {
	text-align: center;
	font-weight: bold;
}

.feature-description {
	font-style: italic;
}

.feature-control {
	margin-top: 10px;
	margin-bottom: 10px;
}

.radio-control,
.check-control {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
	margin-top: 5px;
}

.radio-label,
.check-label{
	margin-left: 20px;
}

.modifier-drawer-container.modal-enter .modifier-drawer {
	-webkit-transform: translate(0,1000px);
	        transform: translate(0,1000px);
}

.modifier-drawer-container.modal-enter-active .modifier-drawer {
	-webkit-transform: translate(0,0);
	        transform: translate(0,0);
	-webkit-transition: -webkit-transform .4s ease-in;
	transition: -webkit-transform .4s ease-in;
	transition: transform .4s ease-in;
	transition: transform .4s ease-in, -webkit-transform .4s ease-in;

}

.modifier-drawer-container.modal-exit .modifier-drawer {
	-webkit-transform: translate(0,0);
	        transform: translate(0,0);
}

.modifier-drawer-container.modal-exit-active .modifier-drawer {
	-webkit-transform: translate(0,1000px);
	        transform: translate(0,1000px);
	-webkit-transition: -webkit-transform .5s ease-out;
	transition: -webkit-transform .5s ease-out;
	transition: transform .5s ease-out;
	transition: transform .5s ease-out, -webkit-transform .5s ease-out;
}
@keyframes mini-lds-double-ring {
	0% {
		-webkit-transform: translate(-50%,-50%) rotate(0);
		transform: translate(-50%,-50%) rotate(0);
	}
	100% {
		-webkit-transform: translate(-50%,-50%) rotate(360deg);
		transform: translate(-50%,-50%) rotate(360deg);
	}
}

@-webkit-keyframes mini-lds-double-ring {
	0% {
		-webkit-transform: translate(-50%,-50%) rotate(0);
		transform: translate(-50%,-50%) rotate(0);
	}
	100% {
		-webkit-transform: translate(-50%,-50%) rotate(360deg);
		transform: translate(-50%,-50%) rotate(360deg);
	}
}

@keyframes mini-lds-double-ring_reverse {
	0% {
		-webkit-transform: translate(-50%,-50%) rotate(0);
		transform: translate(-50%,-50%) rotate(0);
	}
	100% {
		-webkit-transform: translate(-50%,-50%) rotate(-360deg);
		transform: translate(-50%,-50%) rotate(-360deg);
	}
}

@-webkit-keyframes mini-lds-double-ring_reverse {
	0% {
		-webkit-transform: translate(-50%,-50%) rotate(0);
		transform: translate(-50%,-50%) rotate(0);
	}
	100% {
		-webkit-transform: translate(-50%,-50%) rotate(-360deg);
		transform: translate(-50%,-50%) rotate(-360deg);
	}
}

.mini-lds-double-ring {
	position: relative;
}

.mini-lds-double-ring div {
	position: absolute;
	width: 30px;
	height: 30px;
	top: 50%;
	left: 50%;
	border-radius: 50%;
	border: 2px solid #000;
	border-color: #4583b1 transparent #4583b1 transparent;
	-webkit-animation: mini-lds-double-ring 1.3s linear infinite;
	animation: mini-lds-double-ring 1.3s linear infinite;
}

.mini-lds-double-ring div:nth-child(2) {
	width: 20px;
	height: 20px;
	top: 50%;
	left: 50%;
	border-color: transparent black transparent black;
	-webkit-animation: mini-lds-double-ring_reverse 1.3s linear infinite;
	animation: mini-lds-double-ring_reverse 1.3s linear infinite;
}

.mini-lds-double-ring {
	width: 100% !important;
	height: 20px !important;
	overflow: visible;
	-webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
	transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}
.slide-out-header {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.5em;
    line-height: 1em;
    width: 100%;
    text-align: center;
    color: #ffffff;
    background-color: #1c4856;
    /* padding-top: 10px; */
    /* padding-bottom: 10px; */
    max-height: 50px;
    z-index: 99;
}

.split-header {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.split-header .subtext {
    font-size: 0.7em;
}

.slide-out-header.jared {
    flex-direction: column;
    background-color: transparent;
    justify-content: space-between !important;
    padding-top: 0;
    padding-bottom: 0;
    max-height: none;
    /* height: 70px; */
}

.jared .tab-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
    /*
	background-color: transparent;
	*/
}

.jared .slide-out-greeting {
    width: 100%;
    background-color: rgba(59, 132, 177, 0.9);
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    height: 34px;
    border-bottom: 1px solid white;
}

.jared .map-view,
.jared .list-view {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4583b1;
    width: 100%;
    padding: 3px 10px 3px 10px;
    color: white;
    border-bottom: 1px solid #4583b1;
    border-top: 1px solid #4583b1;
    height: 100%;
}

.jared .map-view.map-mode,
.jared .list-view.list-mode {
    color: #c2d5e0;
    background-color: #84a6bd;
    border-top: 1px solid #4583b1;
}

.menu-header {
    color: white !important;
}

/*.main-nav{
	transition: none !important;
}*/

@media screen and (max-width: 1050px) {
    .slide-out-header {
        top: 60px;
    }

    /*.main-nav{
		transition: all 0.1s !important;
	}*/
}

@media screen and (max-width: 750px) {
    /* MOBILE HAMBURGER MENU ALTERED STYLES */

    /*.main-nav{
		top: 48px;
	}
	
	.main-nav.menu-on{
		width: 70%;
		max-height: none;
		top: 48px;
		background-color: white !important;
	}

	.main-nav.menu-on ul{
		background-color: white !important;
	}

	.material-design-hamburger{
		color: white !important;
	}

	.main-nav.menu-on li a{
		color: #3fa1c2;
		font-weight: 500;
	}

	.main-nav.menu-on .menu-header{
		color: #3fa1c2 !important;
		border-bottom: 1px solid #3fa1c2;
		font-size: 24px;
		padding-bottom: 5px;
		font-weight: 600;
	}
	
	.material-design-hamburger__layer{
		background: white;
	}

	header{
		box-shadow: none;
	}*/
}

/*
.main-nav{
	background: #3fa1c2 !important;
	width: 130px;
	top: 6px;
}

.material-design-hamburger{
	zoom: 75%;
	top: 13px;
}*/

.vendor-tile {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    width: 90%;
    padding: 5px 5px 5px 5px;
    background-color: #ffffff;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 3px 4px 11px 2px rgba(0, 0, 0, 0.2);
    z-index: 2;
    /*height: 200px;*/
}

.vendor-popup {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.vendor-tile.highlighted {
    box-shadow: 0 0 11px 0 rgba(69, 131, 177, 0.4);
    -webkit-animation-name: activeBounce;
            animation-name: activeBounce;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

.vendor-tile.pre-render {
    opacity: 0;
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
}

.vendor-tile.post-render {
    opacity: 1;
    -webkit-transition: opacity 0.2s ease, -webkit-transform 0.2s ease;
    transition: opacity 0.2s ease, -webkit-transform 0.2s ease;
    transition: opacity 0.2s ease, transform 0.2s ease;
    transition: opacity 0.2s ease, transform 0.2s ease, -webkit-transform 0.2s ease;
}

.vendor-name {
    text-align: center;
    color: #4583b1;
    font-size: 1.4em;
    font-weight: bold;
}

.order-details .address-lines .vendor-name {
    text-align: left;
}

.vendor-subtitle {
    font-style: italic;
}

.vendor-section-title {
    font-weight: bold;
}

.address-section {
    display: flex;
    align-items: center;
}

.address-section .pointer-icon {
    color: #4583b1;
    margin-left: 10px;
    margin-right: 10px;
}

.platinum-star {
    color: #40d3ba;
}

.preferred-star {
    color: #ffd700;
}

.paren-star {
    white-space: nowrap;
    font-style: normal !important;
}

.vendor-popup .address-section {
    max-width: 250px;
}

.vendor-popup .address-section .pointer-icon {
    margin-left: 0;
}

.vendor-popup .address-section span {
    font-size: 16px;
}

.vendor-popup span.vendor-address-2,
.vendor-popup span.vendor-city-state-zip {
    font-weight: normal;
}

.vendor-popup .register-button,
.address-section .register-button {
    position: relative;
    display: block;
    text-align: center;
    overflow: hidden;
    padding: 10px 10px 10px 10px;
    margin-left: auto;
    margin-right: 20px;
    white-space: nowrap;
    border: 2px solid #4583b1;
    border-radius: 4px;
    z-index: 0;
    cursor: pointer;
    color: #fff; /*4583b1*/
    font-weight: bold;
    min-width: 124px;
    background-color: #4583b1;
}

.vendor-popup .register-button {
    min-width: 200px;
    font-size: 16px;
}

.vendor-popup.order-details .register-button {
    margin-right: 0;
}

.vendor-popup .register-button.inactive {
    color: darkgray;
    border: 2px solid darkgray;
}

.vendor-popup .address-section .register-button {
    margin-left: 10px;
    margin-right: auto;
}

.vendor-popup .register-button:hover,
.address-section .register-button:hover {
    color: #4583b1;
}

.vendor-popup .register-button:after,
.address-section .register-button:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    -webkit-transition: -webkit-transform 0.2s ease;
    transition: -webkit-transform 0.2s ease;
    transition: transform 0.2s ease;
    transition: transform 0.2s ease, -webkit-transform 0.2s ease;
    -webkit-transform: translateX(101%);
            transform: translateX(101%);
    z-index: -1;
}

.vendor-popup .register-button:hover:after,
.address-section .register-button:hover:after {
    -webkit-transition: -webkit-transform 0.2s ease;
    transition: -webkit-transform 0.2s ease;
    transition: transform 0.2s ease;
    transition: transform 0.2s ease, -webkit-transform 0.2s ease;
    -webkit-transform: translateX(0);
            transform: translateX(0);
}

.address-lines {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.vendor-change {
    color: #4583b1;
    cursor: pointer;
    text-decoration: underline;
}

.vendor-address-1,
.vendor-address-2,
.vendor-city-state-zip {
    /*
	font-weight: bold;
	*/
    font-size: 1em;
}

.vendor-tile hr,
.vendor-popup hr {
    width: 80%;
    height: 1px;
    margin: 10px auto 10px;
    color: #e0e0e0;
}

.vendor-popup hr {
    margin: 5px auto 5px;
}

.vendor-popup .vendor-hours-box {
    padding-bottom: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.vendor-hours-box {
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    background-color: rgb(228, 238, 243);
    padding: 0 10px 10px 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

#vendor-showmore .vendor-hours-box {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

#vendor-showmore span {
    font-style: italic;
}

.vendor-showrest {
    color: #4583b1;
    text-decoration: underline;
    cursor: pointer;
}

.popup-tip-anchor {
    height: 0;
    position: absolute;
    width: 300px;
}

.popup-bubble-anchor {
    position: absolute;
    width: 100%;
    bottom: 57px;
    left: 0;
}

.popup-bubble-anchor.below {
    bottom: -150px;
}

.popup-bubble-anchor.below .popup-bubble-content {
    top: 20px;
}

.popup-bubble-anchor::after {
    content: '';
    position: absolute;
    left: 0;
    /* Center the tip horizontally. */
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
    width: 0;
    height: 0;
    /* The tip is 8px high, and 12px wide. */
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
}

.popup-bubble-anchor.above::after {
    top: 0;
    border-top: /* TIP_HEIGHT= */ 8px solid white;
}
.popup-bubble-anchor.below::after {
    top: -148px;
    border-bottom: /* TIP_HEIGHT= */ 8px solid white;
}

.popup-bubble-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 250px;
    height: 160px;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    font-family: sans-serif;
    overflow: visible;
    box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.5);
}

.vendor-detail-cta {
    width: 100%;
    display: flex;
    justify-content: center;
}

.vendor-detail-cta .register-button {
    margin: 0;
}

.view-on-map {
    text-decoration: underline;
    color: #4583b1;
    cursor: pointer;
    font-size: 0.8em;
    margin-top: 5px;
}

@media screen and (max-width: 550px) {
    .vendor-name {
        font-size: 1em;
    }

    .vendor-address-1,
    .vendor-address-2,
    .vendor-city-state-zip {
        /*
		font-weight: bold;
		*/
        font-size: 0.8em;
        line-height: 1em;
    }
    .vendor-popup .register-button,
    .address-section .register-button {
        background-color: #4583b1;
        color: #efefef;
    }
}

@media screen and (max-width: 380px) {
    .address-section .register-button {
        font-size: 0.8em;
    }
}

@-webkit-keyframes activeBounce {
    0% {
        box-shadow: 0 0 11px 0 rgba(69, 131, 177, 0.4);
    }
    40% {
        box-shadow: 0 0 11px 10px rgba(69, 131, 177, 0.4);
    }
}

@keyframes activeBounce {
    0% {
        box-shadow: 0 0 11px 0 rgba(69, 131, 177, 0.4);
    }
    40% {
        box-shadow: 0 0 11px 10px rgba(69, 131, 177, 0.4);
    }
}

/* Red arrow for popup*/
.register-button-container {
    position: relative;
}

@-webkit-keyframes arrowHead {
    0%,
    100% {
        -webkit-transform: rotate(0deg) translateX(0);
                transform: rotate(0deg) translateX(0);
    }
    50% {
        -webkit-transform: rotate(0deg) translateX(10px);
                transform: rotate(0deg) translateX(10px);
    }
}

@keyframes arrowHead {
    0%,
    100% {
        -webkit-transform: rotate(0deg) translateX(0);
                transform: rotate(0deg) translateX(0);
    }
    50% {
        -webkit-transform: rotate(0deg) translateX(10px);
                transform: rotate(0deg) translateX(10px);
    }
}
@-webkit-keyframes arrowLine {
    0%,
    100% {
        -webkit-transform: rotate(321deg) translateX(-7px) translateY(-6.2px);
                transform: rotate(321deg) translateX(-7px) translateY(-6.2px);
    }
    50% {
        -webkit-transform: rotate(321deg);
                transform: rotate(321deg);
    }
}
@keyframes arrowLine {
    0%,
    100% {
        -webkit-transform: rotate(321deg) translateX(-7px) translateY(-6.2px);
                transform: rotate(321deg) translateX(-7px) translateY(-6.2px);
    }
    50% {
        -webkit-transform: rotate(321deg);
                transform: rotate(321deg);
    }
}

.popup-bubble-content {
    overflow: visible;
}
.popup-bubble-content::before {
    content: '';
    position: absolute;
    z-index: 99999;
    right: -219px;
    bottom: 13px;
    width: 200px;
    height: 100px;
}
.popup-bubble-content::after {
    content: '';
    position: absolute;
    z-index: 99999;
    width: 0;
    height: 0;
    /* border-right: 35px solid red; */
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    right: -20px;
    bottom: 6px;
    /* animation: arrowHead 1s infinite; */
}

.red-arrow::before {
    background: red;
    -webkit-clip-path: path('M0,0 C50,50 124,73 205,16 L209,12 C163,69 57,81 2,23 Z');
            clip-path: path('M0,0 C50,50 124,73 205,16 L209,12 C163,69 57,81 2,23 Z');
    -webkit-transform: rotate(321deg);
            transform: rotate(321deg);
    -webkit-animation: arrowLine 1s infinite;
            animation: arrowLine 1s infinite;
}

.red-arrow::after {
    border-right: 35px solid red;
    -webkit-animation: arrowHead 1s infinite;
            animation: arrowHead 1s infinite;
}

.vendor-list {
    display: flex;
    width: 100%;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    height: 555px;
    /* margin-top: 5px; */
    overflow-y: auto;
}

.vendor-list.noscroll {
    overflow-y: hidden;
}

@media screen and (max-width: 1050px) {
    .vendor-list {
        height: 495px;
    }
}

@media screen and (max-width: 750px) {
    .vendor-list {
        height: 463px;
    }
}

.service-selector {
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	left:0;
	top:0;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0,.95);
	z-index: 100;
}

.service-selector-inner {
	height: 100%;
	width: 100%;
}

.service-selector.fade-enter {
	background-color: rgba(0,0,0,0);
}

.service-selector.fade-enter-active {
	background-color: rgba(0,0,0,.95);
	-webkit-transition: background-color .2s ease .2s;
	transition: background-color .2s ease .2s;
}

.service-selector.fade-exit {
	background-color: rgba(0,0,0,0.8);
}

.service-selector.fade-exit-active {
	background-color: rgba(0,0,0,0);
	-webkit-transition: background-color .2s ease .2s;
	transition: background-color .2s ease .2s;
}


.address-container{
	position: absolute;
	left: 50%;
	margin-left: -150px;
	margin-top: -20px;
	top:25px;
	text-align: center;
	overflow: visible;
	width: 300px;
	border: 1px solid white;
	cursor: pointer;
	z-index: 101;
	padding: 5px 0;
	overflow: hidden;
	border-radius: 3px;
}

.service-selector.fade-enter .address-container{
	opacity: 0;
	-webkit-transition: opacity 0.2s ease 0.2s;
	transition: opacity 0.2s ease 0.2s;
}
.service-selector.fade-enter-active .address-container{
	opacity: 1;
}
.service-selector.fade-exit .address-container{
	opacity: 1;
	-webkit-transition: opacity 0.2s ease 0.2s;
	transition: opacity 0.2s ease 0.2s;

}
.service-selector.fade-exit-active .address-container{
	opacity: 0;
}


.address-text {
	color: white;
	line-height: 1 !important;
	font-size: 18px !important;
	padding: 0;
	margin: 0;
}

.address-text.desc{
	font-size: 12px !important;
	margin-bottom: 3px;
}



.address-container:hover {
	color: #efefef;
}

.address-container:after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #3fa1c2;
	-webkit-transition: -webkit-transform .2s ease;
	transition: -webkit-transform .2s ease;
	transition: transform .2s ease;
	transition: transform .2s ease, -webkit-transform .2s ease;
	-webkit-transform: translateX(100%);
	        transform: translateX(100%);
	z-index: -1;
}

.address-container:hover:after {
	-webkit-transition: -webkit-transform .2s ease;
	transition: -webkit-transform .2s ease;
	transition: transform .2s ease;
	transition: transform .2s ease, -webkit-transform .2s ease;
	-webkit-transform: translateX(0);
	        transform: translateX(0);
}
.kingdom-button {
	display: flex;
	cursor: pointer;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-grow: 0;
	overflow: hidden;
	height: 65px;
	font-size: 1em;
	flex-shrink: 0;
	margin: 0 10px 0 10px;
	padding: 0 10px 0 10px;
	box-shadow: none;
	background-color: rgba(59, 132, 177, 1);
	border-top: 1px solid gray;
	border-radius: 12px;
	-webkit-transform: scale(1);
	        transform: scale(1);
}

.kingdom-button:hover {
	background-color: rgba(119, 171, 216, 1);
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
}

.kingdom-button.active {
	background-color: rgba(119, 171, 216, 1);
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
}

.kingdom-button {
	-webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.service-selector.fade-enter .kingdom-button{
	opacity: 0;
}

.service-selector.fade-enter-done .kingdom-button{
	opacity: 1;
	-webkit-transition: opacity .2s ease, -webkit-transform .1s ease;
	transition: opacity .2s ease, -webkit-transform .1s ease;
	transition: opacity .2s ease, transform .1s ease;
	transition: opacity .2s ease, transform .1s ease, -webkit-transform .1s ease;
}

.service-selector.fade-exit .kingdom-button{
	opacity: 1;
}

.service-selector.fade-exit-active .kingdom-button{
	opacity: 0;
	-webkit-transition: opacity .2s ease;
	transition: opacity .2s ease;
}

.kingdom-button, .kingdom-button path {
	box-shadow: none;
	background-color: #4583b1;
	color: #ffffff;
	fill: #ffffff;
}

.kingdom-button svg {
	height: 25px;
	width: 30px;
	flex-basis: 30px;
	flex-shrink: 1;
}

.kingdom-label-mobile {
	display:none;
}


@media only screen and (max-width: 600px) {
	.kingdom-button {
		border-radius: 50px;
		overflow: visible;
	}
	.kingdom-buttons .kingdom-label {
		display: none;
	}

	.kingdom-buttons .kingdom-label-mobile {
		display: block;
		position: absolute;
		text-align: center;
		top: -2.6em;
	}

	.kingdom-buttons .kingdom-button {
		height: 50px;
	}
}



.loader-6{
	top: 40px;
	left: -2.5px;
	width: 30px;
	display: flex;
	justify-content: space-between;
}

.loader-6 span{
	display: inline-block;
	width: 5px;
	height: 20px;
	background-color: #4583b1;
}

.loader-6 span:nth-child(1){
	-webkit-animation: grow 1s ease-in-out infinite;
	        animation: grow 1s ease-in-out infinite;
}

.loader-6 span:nth-child(2){
	-webkit-animation: grow 1s ease-in-out 0.15s infinite;
	        animation: grow 1s ease-in-out 0.15s infinite;
}

.loader-6 span:nth-child(3){
	-webkit-animation: grow 1s ease-in-out 0.30s infinite;
	        animation: grow 1s ease-in-out 0.30s infinite;
}

.loader-6 span:nth-child(4){
	-webkit-animation: grow 1s ease-in-out 0.45s infinite;
	        animation: grow 1s ease-in-out 0.45s infinite;
}

@-webkit-keyframes grow{
	0%, 100%{
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	50%{
		-webkit-transform: scaleY(1.8);
		transform: scaleY(1.8);
	}
}

@keyframes grow{
	0%, 100%{
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	50%{
		-webkit-transform: scaleY(1.8);
		transform: scaleY(1.8);
	}
}
.kingdom-list {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	height: 100%;
	padding-top: 70px;
}

.kingdom-greeting-container,
.kingdom-buttons-container {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
}

.kingdom-greeting {
	margin-top: 100px;
	width: 70%;
	font-size: 36px;
	line-height: 36px;
	color: white;
	text-align: center;
}

.kingdom-greeting.gone {
	opacity: 0 !important;
}

.kingdom-buttons {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	display: flex;
	justify-content: space-around;
	width: 50%;
	z-index: 100;
}

.kingdom-buttons.elevated {
	top: 100px;
	-webkit-transition: top .2s ease .2s;
	transition: top .2s ease .2s;
}

.kingdom-buttons.elevated {
	color: white;
}

.kingdom-greeting.locked {
	margin-top: 20px;
}

.service-selector.fade-enter .kingdom-greeting{
	opacity: 0;
}

.service-selector.fade-enter-done .kingdom-greeting{
	opacity: 1;
	-webkit-transition: opacity .2s ease, top, .2s ease;
	transition: opacity .2s ease, top, .2s ease;
}

.service-selector.fade-exit .kingdom-greeting{
	opacity: 1;
}

.service-selector.fade-exit-active .kingdom-greeting{
	opacity: 0;
	-webkit-transition: opacity .2s ease;
	transition: opacity .2s ease;
}

.kingdom-address-container{
	position: absolute;
	top: 350px;
	text-align: center;
}

.kingdom-address-container.gone{
	opacity: 0;
	-webkit-transition: opacity .2s ease;
	transition: opacity .2s ease;
}

.kingdom-address-text{
	color: white;
	line-height: 14;
	font-size: 14px;
	padding: 0;
}

@media only screen and (max-width: 600px) {
	.kingdom-greeting {
		width: 100%;
		font-size: 26px;
		line-height: 26px;
	}

	.kingdom-buttons {
		height: 30px;
	}

	.kingdom-buttons.elevated {
		top: -40px;
		height: 30px;
		flex-direction: row;
	}
}
.phylum-panel-container {
	position: absolute;
	top: 50px;
	left: 0;
	height: 505px;
	width: 100%;
	overflow-y: hidden;
	overflow-x: auto;
}
.phylum-panel {
	width: 52%;
	height: 355px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: absolute;
	left: 50%;
	top: 150px;
	z-index: 101;
	-webkit-transform: translate(-50%,0);
	        transform: translate(-50%,0);
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: #b7b7b7 transparent;
}

.phylum-panel::-webkit-scrollbar {
	background-color: transparent;
	width: 5px;
}
.phylum-panel::-webkit-scrollbar-thumb {
	background-color: #b7b7b7;
	border-radius: 20px;
}


.phylum-panel-title {
	position: absolute;
	white-space: nowrap;
	left: 50%;
	top: 110px;
	-webkit-transform:translateX(-50%);
	        transform:translateX(-50%);
	color: #dadada;
	font-size: 2em;
}

.phylum-panel-back-button {
	position: absolute;
	display: flex;
	align-items: center;
	width: 45px;
	height: 45px;
	overflow: hidden;
	white-space: nowrap;
	left: 25%;
	top: 100px;
	font-size: 1em;
	color: #dadada;
	border: 2px solid #dadada;
	cursor: pointer;
	border-radius: 30px;
	padding-left:10px;
	padding-right: 10px;
	justify-content: space-between;
	-webkit-transition: width .2s ease, -webkit-transform .2s ease;
	transition: width .2s ease, -webkit-transform .2s ease;
	transition: transform .2s ease, width .2s ease;
	transition: transform .2s ease, width .2s ease, -webkit-transform .2s ease;
}

.phylum-panel-back-button .svg-inline--fa {
	font-size: 2em !important;
}

.back-button-left-arrow {
	margin-left: 2px;
	margin-right: 14px;
}

.phylum-panel-back-button:hover {
	width: 125px;
	-webkit-transition: width .2s ease;
	transition: width .2s ease;
	color: white;
	background-color: #4583b1;
}

.phylum-panel-container.fade-enter .phylum-panel,
.phylum-panel-container.fade-enter .phylum-panel-title,
.phylum-panel-container.fade-enter .phylum-panel-back-button {
	opacity: 0;
}

.phylum-panel-container.fade-enter-done .phylum-panel,
.phylum-panel-container.fade-enter-done .phylum-panel-title,
.phylum-panel-container.fade-enter-done .phylum-panel-back-button {
	opacity: 1;
	-webkit-transition: opacity .2s ease, width .2s ease;
	transition: opacity .2s ease, width .2s ease;
}

.phylum-panel-container.fade-exit .phylum-panel,
.phylum-panel-container.fade-exit .phylum-panel-title,
.phylum-panel-container.fade-exit .phylum-panel-back-button {
	opacity: 1;
}

.phylum-panel-container.fade-exit-active .phylum-panel,
.phylum-panel-container.fade-exit-active .phylum-panel-title,
.phylum-panel-container.fade-exit-active .phylum-panel-back-button {
	opacity: 0;
	-webkit-transition: opacity .2s ease;
	transition: opacity .2s ease;
}

.phylum-panel-container.slide-exit .phylum-panel{
	-webkit-transform: translate(-50%,0);
	        transform: translate(-50%,0);
}

.phylum-panel-container.slide-exit-active .phylum-panel {
	-webkit-transform: translate(-200%,0);
	        transform: translate(-200%,0);
	-webkit-transition: -webkit-transform .2s ease;
	transition: -webkit-transform .2s ease;
	transition: transform .2s ease;
	transition: transform .2s ease, -webkit-transform .2s ease;
}

.phylum-panel-container.slide-enter .phylum-panel {
	-webkit-transform: translate(-200%,0);
	        transform: translate(-200%,0);
}

.phylum-panel-container.slide-enter-active .phylum-panel {
	-webkit-transform: translate(-50%,0);
	        transform: translate(-50%,0);
	-webkit-transition: -webkit-transform .2s ease;
	transition: -webkit-transform .2s ease;
	transition: transform .2s ease;
	transition: transform .2s ease, -webkit-transform .2s ease;
}

.phylum-panel-container.slide-exit .phylum-panel-back-button {
	-webkit-transform: translate(0,0);
	        transform: translate(0,0);
	opacity: 1;
}

.phylum-panel-container.slide-exit-active .phylum-panel-back-button {
	-webkit-transform: translate(-100%,0);
	        transform: translate(-100%,0);
	opacity: 0;
	-webkit-transition: opacity .2s ease, -webkit-transform .2s ease;
	transition: opacity .2s ease, -webkit-transform .2s ease;
	transition: transform .2s ease, opacity .2s ease;
	transition: transform .2s ease, opacity .2s ease, -webkit-transform .2s ease;
}

.phylum-panel-container.slide-enter .phylum-panel-back-button{
	-webkit-transform: translate(-100%,0);
	        transform: translate(-100%,0);
	opacity: 0;
}

.phylum-panel-container.slide-enter-active .phylum-panel-back-button{
	-webkit-transform: translate(0,0);
	        transform: translate(0,0);
	opacity: 1;
	-webkit-transition: opacity .2s ease, -webkit-transform .2s ease;
	transition: opacity .2s ease, -webkit-transform .2s ease;
	transition: transform .2s ease, opacity .2s ease;
	transition: transform .2s ease, opacity .2s ease, -webkit-transform .2s ease;
}

.phylum-panel-container.slide-exit .phylum-panel-title {
	-webkit-transform: translate(-50%,0);
	        transform: translate(-50%,0);
	opacity: 1;
}

.phylum-panel-container.slide-exit-active .phylum-panel-title {
	-webkit-transform: translate(-150%,0);
	        transform: translate(-150%,0);
	opacity: 0;
	-webkit-transition: opacity .2s ease, -webkit-transform .2s ease;
	transition: opacity .2s ease, -webkit-transform .2s ease;
	transition: transform .2s ease, opacity .2s ease;
	transition: transform .2s ease, opacity .2s ease, -webkit-transform .2s ease;
}

.phylum-panel-container.slide-enter .phylum-panel-title{
	-webkit-transform: translate(-150%,0);
	        transform: translate(-150%,0);
	opacity: 0;
}

.phylum-panel-container.slide-enter-active .phylum-panel-title{
	-webkit-transform: translate(-50%,0);
	        transform: translate(-50%,0);
	opacity: 1;
	-webkit-transition: opacity .2s ease, -webkit-transform .2s ease;
	transition: opacity .2s ease, -webkit-transform .2s ease;
	transition: transform .2s ease, opacity .2s ease;
	transition: transform .2s ease, opacity .2s ease, -webkit-transform .2s ease;
}


@media only screen and (max-width: 1200px) {
	.phylum-panel {
		width: 75%;
	}
	.phylum-panel-back-button {
		left: 14%;
	}
	.phylum-panel .list-item {
		margin-left: 2%;
		margin-right: 2%;
	}
}

@media only screen and (max-width: 850px) {
	.phylum-panel {
		width: 100%;
	}
	.phylum-panel-back-button {
		left: 2%;
		color: white;
		background-color: #4583b1;
	}
}

@media only screen and (max-width: 600px) {
	.phylum-panel-title {
		top: 20px;
		text-align: center;
		font-size: 1.5em;
		color: #dadada;
	}
	.phylum-panel-back-button {
		top: 8px;
		height: 45px;
	}
	.phylum-panel-back-button:hover {
		width: 45px;
	}
	.phylum-panel {
		top:60px;
		height: 495px;
	}
}


.list-item {
	padding: 10px 20px 10px 20px;
	display: flex;
	flex-shrink: 0;
	flex-direction: row;
	justify-content: space-between;
	border: 2px solid #b7b7b7;
	border-radius: 30px;
	cursor: pointer;
	margin-top: 10px;
	margin-bottom: 10px;
	-webkit-transform: translateZ(0);
}

.list-item.expanded {
	background-color: lightgray;
}

.item-left-justified, .item-right-justified, .item-center-justified {
	display: flex;
	align-self: center;
	align-items: center;
}

.sub-item {
	margin-left: 2%;
	margin-right: 2%;
}

.sub-item .item-left-justified {
	color: white;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 18px;
}

.sub-item .item-right-justified {
	color: white;
	font-size: 18px;
}

.service-item {
	cursor: default;
}

@media only screen and (min-width: 600px) {
	.list-item:hover {
		-webkit-backface-visibility: hidden;
		-webkit-transform: scale(1.03);
		        transform: scale(1.03);
		background-color: rgba(69, 131, 177, 0.7);
		-webkit-transition: -webkit-transform .1s ease;
		transition: -webkit-transform .1s ease;
		transition: transform .1s ease;
		transition: transform .1s ease, -webkit-transform .1s ease;
	}

	.list-item:hover .item-left-justified {
		color: white;
	}

	.list-item:hover .service-name {
		color: white;
	}

	.list-item:hover .add-test-price {
		color: white;
	}
}

@media only screen and (max-width: 600px) {
	.web-cat-group .list-item {
		padding-right: 5px;
	}

	.item-left-justified, .item-right-justified, .item-center-justified {
		display: flex;
		align-self: normal;
	}
	
	.phylum-panel .item-left-justified, .phylum-panel .item-right-justified {
		align-self: center;
	}
	
	.list-item {
		min-height: 65px;
		max-height: 65px;
		overflow: hidden;
	}
}

.family-panel-container {
	position: absolute;
	top: 50px;
	left: 0;
	height: 505px;
	width: 100%;
	overflow-x: hidden;
	overflow-y: hidden;
}

.family-panel {
	position: absolute;
	padding-top: 5px;
	width: 52%;
	height: 355px;
	top: 145px;
	left: 50%;
	overflow-y: auto;
	overflow-x: visible;
	-webkit-transform: translate(-50%, 0);
	        transform: translate(-50%, 0);
	scrollbar-width: thin;
	scrollbar-color: #b7b7b7 transparent;
}

.family-panel.skipped-kingdom{
	top: 60px;
	height: 397px;
}

.family-panel::-webkit-scrollbar {
	background-color: transparent;
	width: 5px;
}
.family-panel::-webkit-scrollbar-thumb {
	background-color: #b7b7b7;
	border-radius: 20px;
}

.family-panel-title {
	position: absolute;
	left: 50%;
	top: 110px;
	-webkit-transform:translateX(-50%);
	        transform:translateX(-50%);
	color: #dadada;
	font-size: 2em;
}

.family-panel-title.skipped-kingdom{
	top: 28px;
}

.family-panel-back-button {
	position: absolute;
	display: flex;
	align-items: center;
	width: 45px;
	height: 45px;
	overflow: hidden;
	white-space: nowrap;
	left: 25%;
	top: 100px;
	font-size: 1em;
	color: #dadada;
	border: 2px solid #dadada;
	cursor: pointer;
	border-radius: 30px;
	padding-left:10px;
	padding-right: 10px;
	justify-content: space-between;
	-webkit-transition: width .2s ease, -webkit-transform .2s ease;
	transition: width .2s ease, -webkit-transform .2s ease;
	transition: transform .2s ease, width .2s ease;
	transition: transform .2s ease, width .2s ease, -webkit-transform .2s ease;
}

.family-panel-back-button.skipped-kingdom{
	top: 18px;
}

.family-panel-back-button .svg-inline--fa {
	font-size: 2em !important;
}

.family-panel-back-button:hover {
	width: 105px;
	-webkit-transition: width .2s ease;
	transition: width .2s ease;
	color: white;
	background-color: #4583b1;
}

.family-panel-container.fade-exit .family-panel,
.family-panel-container.fade-exit .family-panel-title,
.family-panel-container.fade-exit .family-panel-back-button {
	opacity: 1;
}

.family-panel-container.fade-exit-active .family-panel,
.family-panel-container.fade-exit-active .family-panel-title,
.family-panel-container.fade-exit-active .family-panel-back-button {
	opacity: 0;
	-webkit-transition: opacity .2s ease;
	transition: opacity .2s ease;
}

.family-panel-container.slide-enter .family-panel {
	-webkit-transform: translate(200%, 0);
	        transform: translate(200%, 0);
}

.family-panel-container.slide-enter-active .family-panel {
	-webkit-transform: translate(-50%, 0);
	        transform: translate(-50%, 0);
	-webkit-transition: -webkit-transform .2s ease;
	transition: -webkit-transform .2s ease;
	transition: transform .2s ease;
	transition: transform .2s ease, -webkit-transform .2s ease;
}

.family-panel-container.slide-exit .family-panel {
	-webkit-transform: translate(-50%, 0);
	        transform: translate(-50%, 0);
}

.family-panel-container.slide-exit-active .family-panel {
	-webkit-transform: translate(200%,0);
	        transform: translate(200%,0);
	-webkit-transition: -webkit-transform .2s ease;
	transition: -webkit-transform .2s ease;
	transition: transform .2s ease;
	transition: transform .2s ease, -webkit-transform .2s ease;
}

.family-panel.blur-enter {
	-webkit-filter: blur(4px);
	        filter: blur(4px);
}

.family-panel.blur-enter-done {
	-webkit-filter: none;
	        filter: none;
	-webkit-transition: -webkit-filter .2s ease;
	transition: -webkit-filter .2s ease;
	transition: filter .2s ease;
	transition: filter .2s ease, -webkit-filter .2s ease;
}

.family-panel-container.slide-exit .family-panel-back-button {
	-webkit-transform: translate(0,0);
	        transform: translate(0,0);
	opacity: 1;
}

.family-panel-container.slide-exit-active .family-panel-back-button {
	-webkit-transform: translate(100%,0);
	        transform: translate(100%,0);
	opacity: 0;
	-webkit-transition: opacity .2s ease, -webkit-transform .2s ease;
	transition: opacity .2s ease, -webkit-transform .2s ease;
	transition: transform .2s ease, opacity .2s ease;
	transition: transform .2s ease, opacity .2s ease, -webkit-transform .2s ease;
}

.family-panel-container.slide-enter .family-panel-back-button{
	-webkit-transform: translate(100%,0);
	        transform: translate(100%,0);
	opacity: 0;
}

.family-panel-container.slide-enter-active .family-panel-back-button{
	-webkit-transform: translate(0,0);
	        transform: translate(0,0);
	opacity: 1;
	-webkit-transition: opacity .2s ease, -webkit-transform .2s ease;
	transition: opacity .2s ease, -webkit-transform .2s ease;
	transition: transform .2s ease, opacity .2s ease;
	transition: transform .2s ease, opacity .2s ease, -webkit-transform .2s ease;
}

.family-panel-container.slide-exit .family-panel-title {
	-webkit-transform: translate(-50%,0);
	        transform: translate(-50%,0);
	opacity: 1;
}

.family-panel-container.slide-exit-active .family-panel-title {
	-webkit-transform: translate(50%,0);
	        transform: translate(50%,0);
	opacity: 0;
	-webkit-transition: opacity .2s ease, -webkit-transform .2s ease;
	transition: opacity .2s ease, -webkit-transform .2s ease;
	transition: transform .2s ease, opacity .2s ease;
	transition: transform .2s ease, opacity .2s ease, -webkit-transform .2s ease;
}

.family-panel-container.slide-enter .family-panel-title{
	-webkit-transform: translate(50%,0);
	        transform: translate(50%,0);
	opacity: 0;
}

.family-panel-container.slide-enter-active .family-panel-title{
	-webkit-transform: translate(-50%,0);
	        transform: translate(-50%,0);
	opacity: 1;
	-webkit-transition: opacity .2s ease, -webkit-transform .2s ease;
	transition: opacity .2s ease, -webkit-transform .2s ease;
	transition: transform .2s ease, opacity .2s ease;
	transition: transform .2s ease, opacity .2s ease, -webkit-transform .2s ease;
}

@media only screen and (max-width: 1200px) {
	.family-panel {
		width: 75%;
	}
	.family-panel-back-button {
		left: 14%;
	}
}

@media only screen and (max-width: 850px) {
	.family-panel {
		width: 100%;
	}
	.family-panel-back-button {
		left: 2%;
		top: 95px;
		color: white;
		background-color: #4583b1;
	}
}

@media only screen and (max-width: 600px) {	
	.family-panel-back-button {
		top: 8px;
		height: 45px;
	}

	.back-button-left-arrow {
		height: 26px;
		font-size: 3em;
	}
	.family-panel-back-button:hover {
		width: 45px;
	}
	.family-panel {
		top: 60px;
		height: 495px;
	}
	.family-panel-title {
		white-space: nowrap;
		font-size: 1.5em;
		color: #dadada;
		top: 20px;
		left: 50%;
	}
}

@media only screen and (min-width: 750px) {
	.family-panel.skipped-kingdom{
		height: 445px;
	}
}
.service-detail-block {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.service-detail-block .service-name {
	/*font-weight: bold;*/
	color: white;
	text-align: left;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 18px;
}



.mobile-about {
	display: none;
}

@media only screen and (max-width: 600px) {
	.service-detail-block {
		/*font-size: .70em;*/
	}

	.service-detail-block .service-name {
		font-size: 14px;
	}

	.mobile-about {
		display: inline;
		margin-left: 3px;
		color: #448ab8;
		font-size: .8em;
		font-weight: bold;
		text-decoration: underline;
	}
}
.detail-icon {
	margin-left: 20px;
}
.detail-icon>svg {
	width: 15px;
	height: 15px;
}

.detail-icon path {
	fill: #4583b1;
}

.detail-icon {
	color: #448ab8;
	font-size: .8em;
	font-weight: bold;
}

.list-item:hover .detail-icon {
	color: white;
}

.detail-icon:hover {
	-webkit-transform: scale(1.1);
	        transform: scale(1.1);
	-webkit-transition: -webkit-transform .1s ease;
	transition: -webkit-transform .1s ease;
	transition: transform .1s ease;
	transition: transform .1s ease, -webkit-transform .1s ease;
}

@media only screen and (max-width: 600px) {
	.detail-icon {
		display: none;
	}
}
.add-test-block {
	display: flex;
	align-items: center;
	font-weight: bold;

}

.add-test-price {
	color: #b7b7b7;
	padding-right: 10px;
	font-weight: normal;
	white-space: nowrap;
}

.add-test-block .add-test-button {
	position: relative;
	overflow: hidden;
	padding: 6px 10px 6px 10px;
	margin-left: 5px;
	color: #ddd;
	background-color: transparent;
	border: solid 2px white;
	border-radius: 3px;
	cursor: pointer;
	white-space: nowrap;
	min-width: 85px;
	max-height: 36px;
	font-size: 18px;
}

.add-test-block .add-test-button.thinking {
	overflow: visible;
	-webkit-transition: none;
	transition: none;
}

@media only screen and (min-width: 600px) {
	.add-test-block .add-test-button:hover {
		color: #4583b1;
	}

	.add-test-block .add-test-button:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: white;
		-webkit-transition: -webkit-transform .2s ease;
		transition: -webkit-transform .2s ease;
		transition: transform .2s ease;
		transition: transform .2s ease, -webkit-transform .2s ease;
		-webkit-transform: translateX(100%);
		        transform: translateX(100%);
		z-index: -1;
	}

	.add-test-block .add-test-button.thinking:after {
		-webkit-transform: translateX(0) !important;
		        transform: translateX(0) !important;
	}

	.add-test-block .add-test-button:hover:after {
		-webkit-transition: -webkit-transform .2s ease;
		transition: -webkit-transform .2s ease;
		transition: transform .2s ease;
		transition: transform .2s ease, -webkit-transform .2s ease;
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}

}

@media only screen and (max-width: 600px) {

	.add-test-block {
		height: 65px;
	}

	.add-test-block .add-test-button {
		display: flex;
		align-items: center;
		align-self: normal;
		border-top: none;
		border-bottom: none;
		border-right: none;
		border-radius: 0;
		height: 65px;
		max-height: 65px;
		box-shadow: none;
	}

	.add-test-block .add-test-button:after {
		width: 110%;
	}
	
	.add-test-block .add-test-button.thinking {
		background-color: white;
		color: #4583b1;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
		width: 70%;
		padding-left: 0;
	}
}
.web-cat-header {
	display: flex;
	align-items: center;
	border-radius: 3px;
	/*border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;*/
	background-color: #4583b1;
	font-weight: bold;
	color: #ffffff;
	text-align: left;
	padding-left: 25px;
	position: -webkit-sticky;
	position: sticky;
	top: -5px;
	height: 30px;
	z-index: 10;
}

.web-cat-group {
	margin-left: 2%;
	margin-right: 2%;
}

@media only screen and (max-width: 600px) {
	.web-cat-header {
	}
}
.slide-out {
    position: absolute;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: rgba(211, 211, 211, 0.7);

    overflow-y: auto;

    overflow-x: hidden;
    box-shadow: -4px 0 6px -2px rgba(0, 0, 0, 0.2);
    top: 0px;
    height: 555px;
}

.slide-out.hide {
    width: 0;
    -webkit-transition: width 0.2s ease;
    transition: width 0.2s ease;
}

.slide-out.show {
    width: 464px;
    -webkit-transition: width 0.2s ease;
    transition: width 0.2s ease;
}

@media screen and (max-width: 1050px) {
    .slide-out {
        top: 60px;
        height: 495px;
    }
}

@media screen and (max-width: 750px) {
    .slide-out.show {
        width: 100%;
    }

    .slide-out {
        padding-top: 0;
        top: 92px;
        height: 463px;
    }
}

