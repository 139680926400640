.kingdom-list {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	height: 100%;
	padding-top: 70px;
}

.kingdom-greeting-container,
.kingdom-buttons-container {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
}

.kingdom-greeting {
	margin-top: 100px;
	width: 70%;
	font-size: 36px;
	line-height: 36px;
	color: white;
	text-align: center;
}

.kingdom-greeting.gone {
	opacity: 0 !important;
}

.kingdom-buttons {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: space-around;
	width: 50%;
	z-index: 100;
}

.kingdom-buttons.elevated {
	top: 100px;
	transition: top .2s ease .2s;
}

.kingdom-buttons.elevated {
	color: white;
}

.kingdom-greeting.locked {
	margin-top: 20px;
}

.service-selector.fade-enter .kingdom-greeting{
	opacity: 0;
}

.service-selector.fade-enter-done .kingdom-greeting{
	opacity: 1;
	transition: opacity .2s ease, top, .2s ease;
}

.service-selector.fade-exit .kingdom-greeting{
	opacity: 1;
}

.service-selector.fade-exit-active .kingdom-greeting{
	opacity: 0;
	transition: opacity .2s ease;
}

.kingdom-address-container{
	position: absolute;
	top: 350px;
	text-align: center;
}

.kingdom-address-container.gone{
	opacity: 0;
	transition: opacity .2s ease;
}

.kingdom-address-text{
	color: white;
	line-height: 14;
	font-size: 14px;
	padding: 0;
}

@media only screen and (max-width: 600px) {
	.kingdom-greeting {
		width: 100%;
		font-size: 26px;
		line-height: 26px;
	}

	.kingdom-buttons {
		height: 30px;
	}

	.kingdom-buttons.elevated {
		top: -40px;
		height: 30px;
		flex-direction: row;
	}
}