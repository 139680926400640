.modal-dialog {
	height: 0;
	width: 0;
}

.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0,0.7);
	z-index: 999;
}

.modal-dialog.modal-enter .modal-backdrop {
	background-color: rgba(0,0,0,0);
	transition: background-color .2s ease;
}

.modal-dialog.modal-exit-active .modal-backdrop {
	background-color: rgba(0,0,0,0) !important;
	transition: background-color .2s ease .5s !important;
}

.modal-dialog.modal-enter-active .modal-backdrop,
.modal-dialog.modal-exit .modal-backdrop {
	background-color: rgba(0,0,0,0.7);
	transition: background-color .2s ease;
}

.modal-dialog.modal-enter .modal-content-container,
.modal-dialog.modal-exit-active .modal-content-container {
	width: 0;
	transition: width .2s ease .3s
}

.modal-dialog.modal-enter-active .modal-content-container {
	width: 100%;
	transition: width .2s ease .2s;
}

.modal-dialog.modal-enter .modal-header,
.modal-dialog.modal-enter .modal-body,
.modal-dialog.modal-enter .modal-close,
.modal-dialog.modal-exit-active .modal-header,
.modal-dialog.modal-exit-active .modal-body,
.modal-dialog.modal-exit-active .modal-close {
	opacity: 0 !important;
	transition: opacity .2s ease;
	overflow: hidden;
}


.modal-dialog.modal-enter-done .modal-header,
.modal-dialog.modal-enter-done .modal-body,
.modal-dialog.modal-enter-done .modal-close,
.modal-dialog.modal-exit .modal-header,
.modal-dialog.modal-exit .modal-body,
.modal-dialog.modal-exit .modal-close {
	opacity: 1;
	overflow: auto;
	transition: opacity .2s ease .1s;
}


.modal-content-container {
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	top: 50%;
	left:50%;
	transform: translate(-50%,-50%);
	width: 100%;
	height: 555px;
	background-color: white;
	z-index: 1000;
	max-width: 700px;
}

.modal-close {
	position: absolute;
	right: 20px;
	color: #aaaaaa;
	font-weight: bold;
	cursor: pointer;
}

.modal-close:hover,
.modal-close:focus {
	color: #000000;
	cursor: pointer;
}

.modal-header {
	display: flex;
	justify-content: center;
	font-weight: bold;
	font-size: 1.5em;
	margin-top: 10px;
	margin-bottom: 10px;
	color: #4583b1;
}
.modal-body {
	overflow: auto;
	height: 463px;
}