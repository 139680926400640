.slide-out {
    position: absolute;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: rgba(211, 211, 211, 0.7);

    overflow-y: auto;

    overflow-x: hidden;
    box-shadow: -4px 0 6px -2px rgba(0, 0, 0, 0.2);
    top: 0px;
    height: 555px;
}

.slide-out.hide {
    width: 0;
    transition: width 0.2s ease;
}

.slide-out.show {
    width: 464px;
    transition: width 0.2s ease;
}

@media screen and (max-width: 1050px) {
    .slide-out {
        top: 60px;
        height: 495px;
    }
}

@media screen and (max-width: 750px) {
    .slide-out.show {
        width: 100%;
    }

    .slide-out {
        padding-top: 0;
        top: 92px;
        height: 463px;
    }
}
