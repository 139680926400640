.list-item {
	padding: 10px 20px 10px 20px;
	display: flex;
	flex-shrink: 0;
	flex-direction: row;
	justify-content: space-between;
	border: 2px solid #b7b7b7;
	border-radius: 30px;
	cursor: pointer;
	margin-top: 10px;
	margin-bottom: 10px;
	-webkit-transform: translateZ(0);
}

.list-item.expanded {
	background-color: lightgray;
}

.item-left-justified, .item-right-justified, .item-center-justified {
	display: flex;
	align-self: center;
	align-items: center;
}

.sub-item {
	margin-left: 2%;
	margin-right: 2%;
}

.sub-item .item-left-justified {
	color: white;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 18px;
}

.sub-item .item-right-justified {
	color: white;
	font-size: 18px;
}

.service-item {
	cursor: default;
}

@media only screen and (min-width: 600px) {
	.list-item:hover {
		-webkit-backface-visibility: hidden;
		transform: scale(1.03);
		background-color: rgba(69, 131, 177, 0.7);
		transition: transform .1s ease;
	}

	.list-item:hover .item-left-justified {
		color: white;
	}

	.list-item:hover .service-name {
		color: white;
	}

	.list-item:hover .add-test-price {
		color: white;
	}
}

@media only screen and (max-width: 600px) {
	.web-cat-group .list-item {
		padding-right: 5px;
	}

	.item-left-justified, .item-right-justified, .item-center-justified {
		display: flex;
		align-self: normal;
	}
	
	.phylum-panel .item-left-justified, .phylum-panel .item-right-justified {
		align-self: center;
	}
	
	.list-item {
		min-height: 65px;
		max-height: 65px;
		overflow: hidden;
	}
}
