.modifier-drawer-container {
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0,0.7);
	z-index: 999;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modifier-drawer-container.modal-exit-active {
	background-color: rgba(0,0,0,0);
	transition: background-color .2s ease-out .5s;
}

.modifier-drawer {
	background-color: #e4eef3;
	opacity: .95;
	padding: 10px 10px 10px 10px;
	border-radius: 5px;
}

.modifier-header {
	display: flex;
	justify-content: space-between;
	border-bottom: 2px solid #d3d3d3;
}

.centered{
	justify-content: center;
	text-align: center;
}

.modifier-closer {
	cursor: pointer;
}

.modifier-title {
	font-size: 1.2em;
	font-weight: bold;
}

.modifier-footer {
	display: flex;
	flex-direction: column;
	align-items: center;
}


.modifier-confirm {
	position: relative;
	overflow: hidden;
	padding: 6px 10px 6px 10px;
	margin-left: 5px;

	background-color: transparent;
	border: solid 2px #4583b1;
	border-radius: 3px;
	cursor: pointer;
	white-space: nowrap;
	min-width: 85px;
	max-height: 36px;
}

.modifier-cancel {
	color: #FF3C38;
	background-color: transparent;
	font-size: .8em;
	text-decoration: underline;
	cursor: pointer;
}

.modifier-cancel:hover {
	text-shadow: 0px 0px 20px #FF3C38;
}

.modifier-confirm {
	color: #4583b1;
}

.modifier-confirm:after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	transition: transform .2s ease;

	z-index: -1;
}

.modifier-confirm:after {
	background-color: #4583b1;
	transform: translateX(100%);
}

.modifier-confirm:hover {
	color: white;
}

.modifier-confirm:hover:after {
	transition: transform .2s ease;
	transform: translateX(0);
}

.feature-name {
	text-align: center;
	font-weight: bold;
}

.feature-description {
	font-style: italic;
}

.feature-control {
	margin-top: 10px;
	margin-bottom: 10px;
}

.radio-control,
.check-control {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
	margin-top: 5px;
}

.radio-label,
.check-label{
	margin-left: 20px;
}

.modifier-drawer-container.modal-enter .modifier-drawer {
	transform: translate(0,1000px);
}

.modifier-drawer-container.modal-enter-active .modifier-drawer {
	transform: translate(0,0);
	transition: transform .4s ease-in;

}

.modifier-drawer-container.modal-exit .modifier-drawer {
	transform: translate(0,0);
}

.modifier-drawer-container.modal-exit-active .modifier-drawer {
	transform: translate(0,1000px);
	transition: transform .5s ease-out;
}