.subtype-loading-masker {
	padding: 10px 20px 10px 20px;
	border-bottom: 2px solid #d6d7d6;
}
.subtype-timeline-item {
	background: #fff;
	border-bottom: 1px solid #f2f2f2;
	padding: 10px 20px 10px 20px;
	margin: 0 auto;
	overflow: hidden;
}

@keyframes subtypePlaceHolderShimmer {
	0% {
		background-position: -468px 0
	}
	100% {
		background-position: 468px 0
	}
}

.subtype-animated-background {
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: subtypePlaceHolderShimmer;
	animation-timing-function: linear;
	background: #f6f7f8;
	background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
	background-size: 800px 104px;
	height: 20px;
	position: relative;
}

.subtype-background-masker {
	background: #fff;
	position: absolute;
}

/* Every thing below this is just positioning */

.subtype-background-masker.header-left{
	top: 0;
	left: 200px;
	height: 20px;
	width: 350px;
}


