.location-selector {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 555px;
}

.location-hero {
    width: 100%;
    z-index: 100;
    height: 555px;
    background-color: #3fa1c2;
    overflow: hidden;
    position: absolute;
}

.location-hero-wrapper {
    /* z-index: 97; */
    width: 100%;
    height: 555px;
    max-width: 1366px;
    min-width: 320px;
    position: relative;
    margin: auto;
}

.slogan {
    position: absolute;
    left: 15%;
    top: 30%;
    width: 500px;
    text-align: left;
    z-index: 99;
}

.slogan h2 {
    line-height: 1.2381em;
    background: transparent;
    color: white;
    font-size: 2.5em;
    word-wrap: break-word;
    padding: 0;
    margin: 0;
}

.location-form-wrapper {
    width: 100%;
    height: 555px;
    max-width: 1366px;
    position: absolute;
    overflow: hidden;
    z-index: 101;
}

.location-form-wrapper.small {
    left: 0;
    top: 0;
    width: 600px;
    height: 70px;
}

.loc-input-form {
    position: absolute;
    left: 15%;
    top: 50%;
    display: flex;
    justify-content: space-between;
    width: 565px;
    z-index: 99;

    transition: all 0.5s;
}

.loc-input-form.small {
    top: 10px;
    left: 10px;
    transform-origin: top left;
}

/* We need to hide the serach bar on desktop view when in service selector pipeline*/
.loc-input-form.small.menus {
    display: none;
}
.location-form-wrapper.menus {
    display: none;
}

.location-loading-icon {
    width: 50px;
    position: absolute;
    left: 62%;
    top: 5%;
    z-index: 100;

    transition: all 0.5s;
}

.location-loading-icon.small {
    top: 0;
    left: 0;
    transform-origin: top left;
    zoom: 80%;
}

.location-input-container {
    height: 60px;
    width: 410px;
    box-sizing: border-box;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #ccc;
    position: relative;
}

.location-input-container p {
    padding-top: 0;
    position: absolute;
    top: 2px;
    left: 12px;
    font-size: 12px;
    font-weight: 400;
    color: #000;
    user-select: none;
    cursor: text;
    z-index: 99;
    margin-top: 0;
}

#location-search-input {
    margin-bottom: 5px;
    box-sizing: border-box;
    height: 50px;
    width: 100%;
    font-size: 24px;
    font-weight: 300;
    border-radius: 4px;
    z-index: 98;
    padding: 28px 10px 10px;
    outline: none;
    border: none;
    max-width: 400px;
    position: absolute;
    bottom: 0;
    left: 0;
}

#location-search-input:focus {
    outline: none;
    box-shadow: none;
}

.hs-button {
    height: 60px;
    width: 150px;

    cursor: pointer;
    border-radius: 4px;
    outline: none;

    transition: color 0.1s, background-color 0.1s;

    color: #333;
    background-color: #ffe82d;
    border: 1px solid #decb2f;
    position: relative;
    cursor: pointer;
}

.hs-button::before {
    content: 'Search';
    font-size: 20px;
    font-weight: 600;
}

.hs-button .text {
    position: absolute;
    left: 0;
    top: 0;
    line-height: 57px;
    width: 100%;
    font-weight: 600;
    user-select: none;
}

.hs-button .icon {
    left: 45%;
    top: 1px;
    position: absolute;
    opacity: 0;
    line-height: 56px;
    transition: transform 0.1s, opacity 0.1s;
    user-select: none;
}

.hs-button:hover {
    color: #eee;
    background-color: #e0d581;
    border: 1px solid #bdb36b;
}

.hs-button:hover .icon {
    opacity: 1;
    transform: translateX(45px);
}

.hs-button:active {
    background-color: #e7b642;
    transform: translate(1px, 1px);
}

.location-input-error.fade-enter {
    opacity: 0;
}
.location-input-error.fade-enter-active {
    opacity: 1;
    transition: opacity 0.3s ease;
}
.location-input-error {
    width: 410px;
    height: 30px;
    background-color: #fffce0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 70px;
    border-radius: 5px;
    box-shadow: 1px 1px 3px 0px #00000033;
}

.location-input-error.fade-exit-active {
    opacity: 0;
    transition: opacity 0.3s ease;
}

.location-input-error svg {
    color: #ff684c;
    margin-left: 7px;
}

.location-input-error-text {
    padding: 0;
    color: #ff684c;
    margin-left: 7px;
}

/* MID SIZE MAP LOCATION ALTERATIONS */
@media screen and (max-width: 1050px) {
    .loc-input-form.small {
        left: 0;
        top: 0;
        width: 100%;
    }

    .location-form-wrapper.small {
        left: 0;
        top: 0;
        width: 100%;
    }

    .location-input-container {
        width: 80%;
        left: 0;
        border-radius: 0;
    }

    #location-search-input {
        border-radius: 0;
    }
    .hs-button {
        position: absolute;
        width: 20%;
        right: 0;
        top: 0;
        border-radius: 0;
    }

    .hs-button:before {
        content: '';
    }
    .hs-button .hs-button-search {
        font-size: 20px;
    }

    .logo {
        margin-top: 9px;
    }

    .hs-button:hover {
        color: #333;
        background-color: #ffe82d;
        border: 1px solid #decb2f;
    }
    .hs-button:hover .icon {
        opacity: 0;
    }

    .location-input-error {
        top: 110px;
        left: 8px;
    }
}

/* MOBILE SIZE MAP LOCATION ALTERATIONS */
@media screen and (max-width: 750px) {
    .loc-input-form {
        left: 5%;
        width: 90%;
        display: block;
    }
    .slogan {
        width: 100%;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
    }
    .loc-input-form.small {
        left: 0;
        top: 0px;
    }
    #slide-out {
        padding-top: 0px;
    }

    .service-selector {
        margin-top: 48px;
    }

    .location-form-wrapper.small {
        height: 60px;
    }
}

/* HACKS FOR TINY SCREENS (e.g. iPhone 5) */
@media screen and (max-width: 400px) {
    .slogan {
        zoom: 90%;
        top: 35%;
    }
}

/*
	HERO AND ICON STYLES
*/
.ground img,
.city img,
.truck img,
.cloud img,
.plane img,
.mountains img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
}

.ground {
    z-index: 101;
    position: absolute;
    bottom: -150px;
}

.ground img {
    width: 2000px;
}

.city {
    position: absolute;
    bottom: -10px;
    right: 0;
    width: 400px;
    z-index: 2;
}

.city img {
    width: 100%;
}

.truck {
    position: absolute;
    bottom: 12px;
    left: 15%;
    width: 80px;
    z-index: 1;
}

.truck img {
    width: 100%;
}

.plane {
    right: 15%;
    top: 100px;
}

.mountains {
    position: absolute;
    min-width: 440px;
}

.mountains img {
    width: 100%;
    overflow: hidden;
}

.mountains.m1 {
    width: 40%;
    top: 80%;
    left: 5%;
}

.mountains.m2 {
    width: 40%;
    top: 70%;
    right: 5%;
}

.icon {
    position: absolute;
}
.icon img {
    width: 100px;
}

.cloud {
}
.cloud.c1 {
    left: 3%;
}
.cloud.c2 {
    right: 5%;
    top: 0;
}
.cloud.c3 {
    right: 20%;
}
.cloud.c4 {
    left: 350px;
    top: 20px;
}

.smallicon img {
    width: 70px;
}
.mediumicon img {
    width: 150px;
}
.largeicon img {
    width: 200px;
}

/* MOBILE HERO CUSTOMIZATIONS */
@media screen and (max-width: 750px) {
    .location-selector {
        /* top: 6px; */
    }

    .city {
        width: 200px;
        bottom: 20px;
    }

    .truck {
        width: 50px;
        bottom: 15px;
    }

    .cloud.c1 {
        left: 3%;
        top: 70px;
    }
    .cloud.c2 {
        display: none;
    }
    .cloud.c3 {
        right: 7%;
        top: 70px;
    }
    .cloud.c4 {
        display: none;
    }

    .mountains.m2 {
        display: none;
    }
}

.gm-bundled-control-on-bottom.inset {
    right: 520px !important;
    transition: right 0.3s;
}

.pulse {
    animation: pulse 1.3s infinite;
    box-shadow: 0px 0px 0 8px #ffe82d;
}

@keyframes pulse {
    0% {
        box-shadow: 0px 0px 0 0px #ffe82d;
    }
    50% {
        box-shadow: 0px 0px 0 8px #ffe82d55;
    }
    100% {
        box-shadow: 0px 0px 0 0px #ffe82d;
    }
}
