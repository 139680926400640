.detail-icon {
	margin-left: 20px;
}
.detail-icon>svg {
	width: 15px;
	height: 15px;
}

.detail-icon path {
	fill: #4583b1;
}

.detail-icon {
	color: #448ab8;
	font-size: .8em;
	font-weight: bold;
}

.list-item:hover .detail-icon {
	color: white;
}

.detail-icon:hover {
	transform: scale(1.1);
	transition: transform .1s ease;
}

@media only screen and (max-width: 600px) {
	.detail-icon {
		display: none;
	}
}