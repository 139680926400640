@keyframes mini-lds-double-ring {
	0% {
		-webkit-transform: translate(-50%,-50%) rotate(0);
		transform: translate(-50%,-50%) rotate(0);
	}
	100% {
		-webkit-transform: translate(-50%,-50%) rotate(360deg);
		transform: translate(-50%,-50%) rotate(360deg);
	}
}

@-webkit-keyframes mini-lds-double-ring {
	0% {
		-webkit-transform: translate(-50%,-50%) rotate(0);
		transform: translate(-50%,-50%) rotate(0);
	}
	100% {
		-webkit-transform: translate(-50%,-50%) rotate(360deg);
		transform: translate(-50%,-50%) rotate(360deg);
	}
}

@keyframes mini-lds-double-ring_reverse {
	0% {
		-webkit-transform: translate(-50%,-50%) rotate(0);
		transform: translate(-50%,-50%) rotate(0);
	}
	100% {
		-webkit-transform: translate(-50%,-50%) rotate(-360deg);
		transform: translate(-50%,-50%) rotate(-360deg);
	}
}

@-webkit-keyframes mini-lds-double-ring_reverse {
	0% {
		-webkit-transform: translate(-50%,-50%) rotate(0);
		transform: translate(-50%,-50%) rotate(0);
	}
	100% {
		-webkit-transform: translate(-50%,-50%) rotate(-360deg);
		transform: translate(-50%,-50%) rotate(-360deg);
	}
}

.mini-lds-double-ring {
	position: relative;
}

.mini-lds-double-ring div {
	position: absolute;
	width: 30px;
	height: 30px;
	top: 50%;
	left: 50%;
	border-radius: 50%;
	border: 2px solid #000;
	border-color: #4583b1 transparent #4583b1 transparent;
	-webkit-animation: mini-lds-double-ring 1.3s linear infinite;
	animation: mini-lds-double-ring 1.3s linear infinite;
}

.mini-lds-double-ring div:nth-child(2) {
	width: 20px;
	height: 20px;
	top: 50%;
	left: 50%;
	border-color: transparent black transparent black;
	-webkit-animation: mini-lds-double-ring_reverse 1.3s linear infinite;
	animation: mini-lds-double-ring_reverse 1.3s linear infinite;
}

.mini-lds-double-ring {
	width: 100% !important;
	height: 20px !important;
	overflow: visible;
	-webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
	transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}