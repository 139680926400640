.service-detail-block {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.service-detail-block .service-name {
	/*font-weight: bold;*/
	color: white;
	text-align: left;
	margin-top: 10px;
	margin-bottom: 10px;
	font-size: 18px;
}



.mobile-about {
	display: none;
}

@media only screen and (max-width: 600px) {
	.service-detail-block {
		/*font-size: .70em;*/
	}

	.service-detail-block .service-name {
		font-size: 14px;
	}

	.mobile-about {
		display: inline;
		margin-left: 3px;
		color: #448ab8;
		font-size: .8em;
		font-weight: bold;
		text-decoration: underline;
	}
}