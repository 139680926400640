.vendor-list {
    display: flex;
    width: 100%;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    height: 555px;
    /* margin-top: 5px; */
    overflow-y: auto;
}

.vendor-list.noscroll {
    overflow-y: hidden;
}

@media screen and (max-width: 1050px) {
    .vendor-list {
        height: 495px;
    }
}

@media screen and (max-width: 750px) {
    .vendor-list {
        height: 463px;
    }
}
