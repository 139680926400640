.web-cat-header {
	display: flex;
	align-items: center;
	border-radius: 3px;
	/*border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;*/
	background-color: #4583b1;
	font-weight: bold;
	color: #ffffff;
	text-align: left;
	padding-left: 25px;
	position: -webkit-sticky;
	position: sticky;
	top: -5px;
	height: 30px;
	z-index: 10;
}

.web-cat-group {
	margin-left: 2%;
	margin-right: 2%;
}

@media only screen and (max-width: 600px) {
	.web-cat-header {
	}
}